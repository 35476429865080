import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import Posts from "./Posts";
import UpgreadePlan from "../UpgreadePlan/UpgreadePlan";
import moment from "moment";
import ChangePasswordForm from "./Changepassword";
import Suggestionbox from "./Suggestionbox";
import QRCode from "react-qr-code";

const Userprofile = () => {

  const [bannerImage, setBannerImage] = useState("");
  const [themeImage, setThemeImage] = useState("select2.jpg");
  const [friendsdata, setFriendsData] = useState([]);
  const [blockdata, setBlockData] = useState([]);
  let curentlogin = JSON.parse(window.localStorage.getItem("user"));
  const [copied, setCopied] = useState(false);
  const [fields, setFields] = useState([]);
  const [isViprole, setIsViprole] = useState(false);
  const tagInput = useRef();
  const [input, setInput] = useState({
    name: "",
    email: "",
    lname: "",
    mobile: "",
    profession: "",
    subprofession: "",
    professionview: "",
    subprofessionview: "",
    buisnessname: "",
    days: "",
    address: "",
    description: "",
    age: "",
    uid: "",
    referralid: "",
    plan: "",
    planstatus: "",
    expireddate: "",
    university: "",
    certificate: "",
    licence: "",
    drivinglicense: "",
    references: "",
    facebook: "",
    twitter: "",
    tumbler: "",
    snapchat: "",
    amazon: "",
    ebay: "",
    messenger: "",
    telegram: "",
    tiktok: "",
    Reddit: "",
    WeChat: "",
    Instagram: "",
    whatsapp: "",
    marital_status: "",
    firstname: "",
    lastname: "",
    image: "",
    passportimage: "",
    bannerimage: "",
    lawyers_state: "",
    lawyers_state_certificate: "",
    drivinglicenseimage: "",
    passportimage: "",
  });
  const [stripe, setStripe] = useState({
    holdername: '',
    routingnumber: '',
    accountnumber: '',
    email: '',
    city: '',
    addressone: '',
    addresstwo: '',
    state: '',
    zipcode: '',
    ssn: '',
    dob: '',
    firstname: '',
    lastname: '',
    phone: '',
    gender: '',
    company_name: '',
    company_tin: '',
  });

  const [stripeStatus, setStripeStatus] = useState('Pending');
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    mobile: "",
    references: "",
    marital_status: "",
    address: "",
    age: "",
    description: "",
  });

  const [themecolor, setThemecolor] = useState("#016afb");
  const [themeimage, setThemeimage] = useState("");
  const [setbannerimage, setSetbannerimage] = useState(
    "http://domaintobesocial.com/images/bannerimage.png"
  );


  const [showAdvertisement, setShowAdvertisement] = useState(false);
  const [tags, setTags] = useState([]);
  const [StripeId, setStripeId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [galleryimages, setGalleryimages] = useState([]);
  const [businesscardimages, setBusinesscardimages] = useState([]);
  const [referralData, setReferral] = useState([]);
  const [previewSrc, setPreviewSrc] = useState("");
  const [files, setFiles] = useState([]);
  const [limit, setLimit] = useState([]);
  const [astrickSignImageInput, setAstrickSignImageInput] = useState(false);
  const [checkall, setCheckAll] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [update, setupdate] = useState(0);
  const [childVisible, setChildVisible] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [licenseImage, setLicenseImage] = useState(null);
  const [buisnessFile, setBuisnessFile] = useState([]);
  const [lawyersStateCertificate, setLawyersStateCertificate] = useState(null);
  const [Degree_Certificate_Certificate, setDegree_Certificate_Certificate] = useState(null);
  const [profession, setProfession] = useState([]);
  const [friendsrequests, setFriendsRequests] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [followingData, setFollowingData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [uid, setUid] = useState("");
  const [getAdvertisement, setGetAdvertisement] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const formData = new FormData();
        formData.append("userId", curentlogin.value);
  
        const response = await axios.post(
          `https://domaintobesocial.com/domaintobe/userdelete`,
          formData // Pass formData to the request
        );
  
        // Check the response status to determine if the deletion was successful
        if (response.status === 200) {
          alert('Delete Successful');
          window.location.href='/'
        } else {
          alert('Delete Failed');
          console.error('Error deleting:', response.data);
        }
      } catch (error) {
        alert('Delete Failed');
        console.error('Error deleting:', error);
      }
    }
  };
  const handleCardChange = (e) => {
    setBuisnessFile([...buisnessFile, ...e.target.files]);
  };
  const handleLicenseImage = (e) => {
    setLicenseImage(e.target.files[0]);
  };

  const handleLawyersStateCertificate = (e) => {
    setLawyersStateCertificate(e.target.files[0]);
  };
  const handleDegree_Certificate_Certificate = (e) => {
    setDegree_Certificate_Certificate(e.target.files[0]);
  };

  const handleBusiness = (event) => {
    event.preventDefault();

    if (input.profession) {
      // document.getElementById("loadingicon").style.display = "block";
      var obj = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();
      formData.append("lawyers_state", input.lawyers_state);
      formData.append("lawyers_state_certificate",lawyersStateCertificate);
      formData.append("profession", input.profession);
      formData.append("buisnessname", input.buisnessname);
      formData.append("university", input.university);
      formData.append("certificate", input.certificate);
      formData.append("days", input.days);
      formData.append("subcategory", input.subprofession);
      formData.append("from", input.from);
      formData.append("to", input.to);
      formData.append("licenseimage", licenseImage);
      formData.append("userid", obj.value);

      buisnessFile.forEach((file) => formData.append("files[]", file));

      axios
        .post(
          "https://domaintobesocial.com/domaintobe/businessdetails",
          formData
        )
        .then((res) => {
          if (res.data.message === "success") {
            // document.getElementById("loadingicon").style.display = "none";
            alert("Successfully Update");
            // Assuming you have a function to fetch and update data, replace with the actual function
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const [subcategoryprofession, setSubcategoryProfession] = useState([]);

  const handleProfession = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });

    const formData = new FormData();
    formData.append("id", event.target.value);

    axios
      .post(
        "https://domaintobesocial.com/domaintobe/getprofessionsubcategory",
        formData
      )
      .then((res) => {
        setSubcategoryProfession(res.data.result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const onShowFields = () => {
    setChildVisible((prevChildVisible) => !prevChildVisible);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };
  const inputKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) {
      setTags([...tags, e.target.value]);
      e.target.value = "";
    }
  };
  const removeTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const bannerChange = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      banner: e.target.files[0],
    }));
  };

  const drivinglicenseChange = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      drivinglicense: e.target.files[0],
    }));
  };

  const passportChange = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      passport: e.target.files[0],
    }));
  };

  const studentidChange = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      studentid: e.target.files[0],
    }));
  };

  function validate(input) {
    let errors = {};
    let isValid = true;

    if (!input["profession"]) {
      isValid = false;
      errors["profession"] = "Please enter your profession.";
    }

    if (!input["buisnessname"]) {
      isValid = false;
      errors["buisnessname"] = "Please enter your buisnessname.";
    }

    if (!input["days"]) {
      isValid = false;
      errors["days"] = "Please add your day.";
    }

    if (!input["university"]) {
      isValid = false;
      errors["university"] = "Please enter your university.";
    }

   

    return { isValid, errors };
  }
  const handleAddField = () => {
    setFields([...fields, { value: "" }]);
  };
  const handleChange2 = (index, event) => {
    const newFields = [...fields];
    newFields[index].value = event.target.value;
    setFields(newFields);
  };

  const handleSubmitSocial = (event) => {
    event.preventDefault();
    // document.getElementById('loadingicon').style.display = 'block';

    const obj = JSON.parse(window.localStorage.getItem("user"));
    const formData3 = new FormData();
    formData3.append("facebook", input.facebook);
    formData3.append("twitter", input.twitter);
    formData3.append("tumbler", input.tumbler);
    formData3.append("snapchat", input.snapchat);
    formData3.append("amazon", input.amazon);
    formData3.append("ebay", input.ebay);
    formData3.append("whatsapp", input.whatsapp);
    formData3.append("messenger", input.messenger);
    formData3.append("telegram", input.telegram);
    formData3.append("instagram", input.Instagram);
    formData3.append("tiktok", input.tiktok);
    formData3.append("wechat", input.WeChat);
    formData3.append("reddit", input.Reddit);
    formData3.append("userid", obj.value);
    formData3.append("socialslink", JSON.stringify(fields)); // Assuming fields is defined somewhere
    formData3.append("type", "social");

    axios
      .post("https://domaintobesocial.com/domaintobe/saveinfo", formData3)
      .then((res) => {
        // document.getElementById('loadingicon').style.display = 'none';
        if (res.data.message === "success") {
          alert("Successfully Update");
          // Assuming you have a function to fetch and update data, replace with the actual function
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(input)) {
      // var imgsrc = document.getElementById("myImg").src;
      // if(imgsrc == 'https://localhost:3000/blank'){
      //     var useimage = '';
      // }else{
      //     var useimage = imgsrc;
      // }
      // document.getElementById('loadingicon').style.display = 'block';
      var obj = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();
      formData.append("name", input.name);
      // formData.append('lname', input.lname);
      formData.append("firstname", input.firstname);
      formData.append("lastname", input.lastname);
      formData.append("email", input.email);
      formData.append("mobile", input.mobile);
      formData.append("marital_status", input.marital_status);
      //formData.append('profession', input.profession);
      //formData.append('buisnessname', input.buisnessname);
      //formData.append('university', input.university);
      //formData.append('certificate', input.certificate);
      //formData.append('licence', input.licence);
      formData.append("references", input.references);
      //formData.append('days', input.days);
      formData.append("address", input.address);
      formData.append("age", input.age);
      formData.append("description", input.description);
      //formData.append('from', input.from);
      //formData.append('to', input.to);
      formData.append("tags", tags);
      formData.append("userid", obj.value);
      // formData.append('image', useimage);
      formData.append("banner", input.banner);
      formData.append("drivinglicense", input.drivinglicense);
      formData.append("passport", input.passport);
      formData.append("studentid", input.studentid);
      formData.append("type", "profile");
      formData.append("facebook", input.facebook);
      formData.append("twitter", input.twitter);
      formData.append("tumbler", input.tumbler);
      formData.append("snapchat", input.snapchat);
      formData.append("amazon", input.amazon);
      formData.append("ebay", input.ebay);
      formData.append("messanger", input.messenger);
      formData.append("telegram", input.telegram);
      formData.append("tiktok", input.tiktok);
      formData.append("instagram", input.Instagram);
      formData.append("whatsapp", input.whatsapp);
      // formData.append('businesscard', buisnessFile);
      formData.append("bannerimage", setbannerimage);
      axios
        .post("https://domaintobesocial.com/domaintobe/saveinfo", formData)
        .then((res) => {
          if (res.data.message == "success") {
            alert("Successfully Update");
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const fileSelectedHandler = (e) => {
    setAstrickSignImageInput(false);
    setFiles([...files, ...e.target.files]);
  };

  const uploadGallery = (e) => {
    if (files[0]) {
      e.preventDefault();
      let curentlogin = JSON.parse(window.localStorage.getItem("user"));
    

      const formData = new FormData();
      formData.append("id", curentlogin.value);

      files.forEach((file) => {
        if (file.size / 1000 > 2000) {
          setLimit([...limit, true]);
        } else {
          setLimit([...limit, false]);
          formData.append("files[]", file);
        }
      });

      if (!limit.includes(true)) {
        axios
          .post(
            "https://domaintobesocial.com/domaintobe/galleryimages",
            formData
          )
          .then((res) => {
            if (res.data.message === "Successfully Upload") {
              swal("Success", res.data.message, "success");
              // Assuming you have a function to update necessary data after upload
              // Replace  with your actual function
              setupdate(update + 1);
            } else {
              swal("Oops!", res.data.message, "error");
            }

            setFiles([]);
            setLimit([]);
            document.getElementById("loadingicon").style.display = "none";
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        swal("Oops!", "Please upload Image size under 2mb", "error");
        setFiles([]);
        setLimit([]);
        document.getElementById("loadingicon").style.display = "none";
      }
    } else {
      setAstrickSignImageInput(true);
      swal("Please Select The Image");
    }
  };
  let vipimage, gallerybutton, theme, bannerimage, businessbutton, galleroption;

  const selecthemecolor = (color, id) => {
    const currentuser = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append("user", currentuser.value);
    formData.append("color", color);
    formData.append("image", id);

    axios
      .post(
        "https://domaintobesocial.com/domaintobe/changetheme",
        formData
      )
      .then((res) => {
        if (res.data.message === "success") {
          setupdate(update + 1);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  if (isViprole) {
    vipimage = (
      <div className="report_btni">
        <img src="/images/vip.png" alt="images" />
      </div>
    );
    gallerybutton = (
      <div className="uplodim">
        <input
          type="file"
          multiple
          onChange={fileSelectedHandler}
          accept=".jpg,.jpeg,.png, .mp4"
        />
        Choose images
        <button type="button" onClick={uploadGallery}>
          Upload
        </button>
      </div>
    );

    theme = (
      <div className="user2">
        <h3>Select theme</h3>
        <ul className="llctthems">
          <li>
            <span
              onClick={() => selecthemecolor("#016afb", "select2.jpg")}
              style={{ backgroundImage: `url(/images/select2.jpg)` }}
            ></span>
          </li>
          <li>
            <span
              onClick={() => selecthemecolor("#66a21c", "select1.jpg")}
              style={{ backgroundImage: `url(/images/select1.jpg)` }}
            ></span>
          </li>
          <li>
            <span
              onClick={() => selecthemecolor("#e3611c", "select3.jpg")}
              style={{ backgroundImage: `url(/images/select3.jpg)` }}
            ></span>
          </li>
          <li>
            <span
              onClick={() => selecthemecolor("#000116", "select4.jpg")}
              style={{ backgroundImage: `url(/images/select4.jpg)` }}
            ></span>
          </li>
        </ul>
      </div>
    );

    businessbutton = (
      <li>
        <a data-toggle="tab" href="#business" onClick={closeMenu}>
          Business Details
        </a>
      </li>
    );
    galleroption = (
      <li>
        <a data-toggle="tab" href="#Gallery" onClick={closeMenu}>
          Gallery
        </a>
      </li>
    );
  } else {
    vipimage = "";
    gallerybutton = (
      <div className="uplodim">
        <input
          type="file"
          multiple
          onChange={fileSelectedHandler}
          accept=".jpg,.jpeg,.png, .mp4"
        />
        Choose images
        <button type="button" onClick={uploadGallery}>
          Upload
        </button>
      </div>
    );
    theme = "";
    businessbutton = "";
    galleroption = (
      <li>
        <a data-toggle="tab" href="#Gallery" onClick={closeMenu}>
          Gallery
        </a>
      </li>
    );
  }

  const getFormattedDate = (date) => {
    const test = new Date(date * 1000);
    return moment(test).format("YYYY/MM/DD");
  };
  // useEffect(() => {
  //   const fetchData = async () => {

  //     try {
  //       const formData = new FormData();
  //       formData.append('uid', curentlogin.value);

  //       const response = await axios.post('https://domaintobesocial.com/domaintobe/getAccountDetails',formData);

  //       if (response.data.status === 'success') {
  //         if (response.data.details) {
  //           setStripe((prevStripe) => ({
  //             ...prevStripe,
  //             ...response.data.details,
  //           }));
  //         }

  //         if (response.data.data && response.data.data.individual?.verification.status) {
  //           setStripeStatus(response.data.data.individual.verification.status);
  //         }
  //       } else {
  //         alert(response.data.message);
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //     try {
  //       const formData = new FormData();
  //       formData.append('id', curentlogin.value);

  //       const response = await axios.post('https://domaintobesocial.com/domaintobe/getfriendrequests', formData);

  //       if (response.data.status === 'data') {
  //         setFriendsRequests(response.data.message);
  //       } else {
  //         alert(response.data.message);
  //       }
  //     } catch (error) {
  //       alert('Invalid Login1');
  //     }
  //     try {
  //       const formData = new FormData();
  //       formData.append('id', curentlogin.value);
  //       formData.append('user', curentlogin.value);

  //       const response = await axios.post('https://domaintobesocial.com/domaintobe/getfriendlist', formData);

  //       if (response.data.status === 'data') {
  //         setFriendsData(response.data.message);
  //       } else if (response.data.status === 'no') {
  //         // Handle 'no' status if needed
  //       } else {
  //         alert(response.data.message);
  //       }
  //     } catch (error) {
  //       alert('Invalid Login1');
  //     }

  //     try {
  //       const response6 = await axios.get(
  //         "https://domaintobesocial.com/domaintobe/getprofessions"
  //       );
  //       setProfession(response6.data.message);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //     try {
  //       const formData = new FormData();
  //       formData.append("id", curentlogin.value);
  //       formData.append("user", curentlogin.value);
  //       const response = await axios.post(
  //         "https://domaintobesocial.com/domaintobe/getuserprofile",
  //         formData
  //       );

  //       const { message } = response.data;

  //       setInput((prevInput) => ({
  //         ...prevInput,
  //         name: message.name,
  //         email: message.email,
  //         lname: message.lname,
  //         mobile: message.mobile,
  //         profession: message.profession,
  //         subprofession: message.professionsubcategory,
  //         professionview: message.professionname,
  //         subprofessionview: message.subprofessionname,
  //         buisnessname: message.buisnessname,
  //         days: message.days,
  //         address: message.address,
  //         description: message.description,
  //         age: message.age,
  //         uid: message.id,
  //         referralid: message.referralid,
  //         plan: message.plan,
  //         planstatus: message.planstatus,
  //         expireddate: message.expireddate,
  //         university: message.university,
  //         certificate: message.certificate,
  //         licence: message.licence,
  //         drivinglicense: message.drivinglicense,
  //         references: message.references,
  //         facebook: message.facebook,
  //         twitter: message.twitter,
  //         tumbler: message.tumbler,
  //         snapchat: message.snapchat,
  //         amazon: message.amazon,
  //         ebay: message.ebay,
  //         messenger: message.messenger,
  //         telegram: message.telegram,
  //         tiktok: message.tiktok,
  //         Reddit: message.reddit,
  //         WeChat: message.wechat,
  //         Instagram: message.instagram,
  //         whatsapp: message.whatsapp,
  //         marital_status: message.marital_status,
  //         firstname: message.firstname,
  //         lastname: message.lastname,
  //         image: message.image,
  //         passportimage: message.passportimage,
  //         bannerimage: message.bannerimage,
  //         lawyers_state: message.lawyers_state,
  //         lawyers_state_certificate: message.lawyers_state_certificate,
  //       }));
  //       setFields(JSON.parse(message.socialslink));

  //       setThemecolor(message.themecolor || "#016afb");
  //       setThemeimage(message.themeimage || "select2.jpg");
  //       setSetbannerimage(
  //         message.bannerimage ||
  //           "http://domaintobesocial.com/images/bannerimage.png"
  //       );

  //       setTags(message.tags);
  //       setStripeId(message.stripe_id);
  //       setFrom(message.from);
  //       setTo(message.to);
  //       setGalleryimages(message.galleryimages);
  //       setBusinesscardimages(message.businesscard);

  //       setIsViprole(message.roles === "vip");

  //       const imageSrc = message.image || "/images/blank.png";
  //       const preview = document.querySelector("#myImg");
  //       preview.src = imageSrc;

  //       setReferral(response.data.reff);

  //       setCopied(false); // Reset copied state

  //       subcategoryprofessionget()
        
  //     } catch (error) {
  //       console.error("Error fetching user profile:", error.message);
  //     }
  //     // try {
  //     //   const formData = new FormData();
  //     //   formData.append('id', curentlogin.value);

  //     //   const response = await axios.post(
  //     //     'https://domaintobesocial.com/domaintobe/getusersallposts',
  //     //     formData
  //     //   );

  //     //   if (response.data.status === 'data') {
  //     //     console.log('post data', response.data.message);
  //     //     setPostsData(response.data.message.newsfeed);
  //     //     setHelpsData(response.data.message.helps);
  //     //     setPosts(response.data.message.posts);
  //     //   } else {
  //     //     alert(response.data.message);
  //     //   }
  //     // } catch (error) {
  //     //   console.log(error.message);
  //     // }

  
  //   };

  //   fetchData();
  // }, [update]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData1 = new FormData();
        formData1.append('uid', curentlogin.value);
        
        const formData2 = new FormData();
        formData2.append('id', curentlogin.value);
        
        const formData3 = new FormData();
        formData3.append('id', curentlogin.value);
        formData3.append('user', curentlogin.value);
        
        const formData4 = new FormData();
        formData4.append("id", curentlogin.value);
        formData4.append("user", curentlogin.value);
  
        const [
          accountDetailsResponse,
          friendRequestsResponse,
          friendListResponse,
          professionsResponse,
          userProfileResponse
        ] = await Promise.all([
          axios.post('https://domaintobesocial.com/domaintobe/getAccountDetails', formData1),
          axios.post('https://domaintobesocial.com/domaintobe/getfriendrequests', formData2),
          axios.post('https://domaintobesocial.com/domaintobe/getfriendlist', formData3),
          axios.get('https://domaintobesocial.com/domaintobe/getprofessions'),
          axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData4)
        ]);
  
        // Handle responses
        if (accountDetailsResponse.data.status === 'success') {
          if (accountDetailsResponse.data.details) {
            setStripe((prevStripe) => ({
              ...prevStripe,
              ...accountDetailsResponse.data.details,
            }));
          }
  
          if (accountDetailsResponse.data.data?.individual?.verification?.status) {
            setStripeStatus(accountDetailsResponse.data.data.individual.verification.status);
          }
        } else {
          alert(accountDetailsResponse.data.message);
        }
  
        if (friendRequestsResponse.data.status === 'data') {
          setFriendsRequests(friendRequestsResponse.data.message);
        } else {
          alert(friendRequestsResponse.data.message);
        }
  
        if (friendListResponse.data.status === 'data') {
          setFriendsData(friendListResponse.data.message);
        } else if (friendListResponse.data.status === 'no') {
          // Handle 'no' status if needed
        } else {
          alert(friendListResponse.data.message);
        }
  
        setProfession(professionsResponse.data.message);
  
        const { message } = userProfileResponse.data;
        setInput((prevInput) => ({
          ...prevInput,
          name: message.name,
          email: message.email,
          lname: message.lname,
          mobile: message.mobile,
          profession: message.profession,
          subprofession: message.professionsubcategory,
          professionview: message.professionname,
          subprofessionview: message.subprofessionname,
          buisnessname: message.buisnessname,
          days: message.days,
          address: message.address,
          description: message.description,
          age: message.age,
          uid: message.id,
          referralid: message.referralid,
          plan: message.plan,
          planstatus: message.planstatus,
          expireddate: message.expireddate,
          university: message.university,
          certificate: message.certificate,
          licence: message.licence,
          drivinglicense: message.drivinglicense,
          references: message.references,
          facebook: message.facebook,
          twitter: message.twitter,
          tumbler: message.tumbler,
          snapchat: message.snapchat,
          amazon: message.amazon,
          ebay: message.ebay,
          messenger: message.messenger,
          telegram: message.telegram,
          tiktok: message.tiktok,
          Reddit: message.reddit,
          WeChat: message.wechat,
          Instagram: message.instagram,
          whatsapp: message.whatsapp,
          marital_status: message.marital_status,
          firstname: message.firstname,
          lastname: message.lastname,
          image: message.image,
          passportimage: message.passportimage,
          bannerimage: message.bannerimage,
          lawyers_state: message.lawyers_state,
          lawyers_state_certificate: message.lawyers_state_certificate,
        }));
        setFields(JSON.parse(message.socialslink));
  
        setThemecolor(message.themecolor || "#016afb");
        setThemeimage(message.themeimage || "select2.jpg");
        setSetbannerimage(
          message.bannerimage ||
            "http://domaintobesocial.com/images/bannerimage.png"
        );
  
        setTags(message.tags);
        setStripeId(message.stripe_id);
        setFrom(message.from);
        setTo(message.to);
        setGalleryimages(message.galleryimages);
        setBusinesscardimages(message.businesscard);
  
        setIsViprole(message.roles === "vip");
  
        const imageSrc = message.image || "/images/blank.png";
        const preview = document.querySelector("#myImg");
        preview.src = imageSrc;
  
        setReferral(userProfileResponse.data.reff);
  
        setCopied(false); // Reset copied state
  
        subcategoryprofessionget()
  
      } catch (error) {
        console.error('Error during data fetching:', error.message);
      } finally {
        document.getElementById('loadingicon').style.display = 'none';
      }
    };
  
    fetchData();
  }, [update]);
  
  let subcategoryprofessionget=()=>{
    const formData = new FormData();
    formData.append("id", 40);

    axios
      .post(
        "https://domaintobesocial.com/domaintobe/getprofessionsubcategory",
        formData
      )
      .then((res) => {
        setSubcategoryProfession(res.data.result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  const handleUserCover = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          setBannerImage(reader.result);
        },
        false
      );

      reader.readAsDataURL(file);

      const currentuser = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();
      formData.append("user", currentuser.value);
      formData.append("image", file);
      formData.append("type", "1");

      try {
        const res = await axios.post(
          "https://domaintobesocial.com/domaintobe/uploadimage",
          formData
        );

        if (res.data.message === "success") {
          // Update state or trigger any other necessary actions upon successful upload
          // For example: componentDidMount();
          setupdate(update + 1);
        } else {
          alert(res.data.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const userimage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        // Convert image file to base64 string
        setPreviewSrc(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }

    const formData = new FormData();
    formData.append("user", curentlogin.value);
    formData.append("image", e.target.files[0]);

    axios
      .post(
        "https://domaintobesocial.com/domaintobe/uploadimage",
        formData
      )
      .then((res) => {
        if (res.data.message === "success") {
          // Assuming you have a function to update necessary data after image upload
          // Replace  with your actual function
          setupdate(update + 1);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  let openClose = () => {
    $(".dash_sidebar").toggleClass("main");
  };

  let openProfile = () => {
    $("#mp1").toggleClass("diplay1");
    $("#mp2").toggleClass("diplay1");
  };
  let openPop = () => {
    // alert('asdfasdf');
    $(".dash_sidebar").toggleClass("main");
  };
  let editpage = () => {
    $("#mp1").toggleClass("diplay1");
    $("#mp2").toggleClass("diplay1");
  };
  let changebutton = () => {
    document.getElementsByName("submitform")[0].type = "submit";
  };
  const acceptFriendrequest = (i,id) => {
    const formData = new FormData();
    formData.append("id", id);
    axios
      .post(
        "https://domaintobesocial.com/domaintobe/acceptfriendrequests",
        formData
      )
      .then((res) => {
        swal(res.data.message);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const unFriendrequest = (id) => {
    const formData = new FormData();
    formData.append("id", id);
    axios
      .post(
        "https://domaintobesocial.com/domaintobe/removefriend",
        formData
      )
      .then((res) => {
        alert(res.data.message);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleInputAllGallery = (event) => {
    if (event.target.checked) {
      setCheckAll(event.target.id);
      console.log(event.target.checked)
    } else {
      setCheckAll("");
    }
  };
  const stripeValidate = () => {
    const input = stripe;
    let isValid = true;
  
    if (!input.holdername) {
      isValid = false;
      alert('holdername');
    }
  
    if (!input.firstname) {
      isValid = false;
      alert('firstname');
    }
  
    if (!input.lastname) {
      isValid = false;
      alert('lastn');
    }
  
    if (!input.email) {
      isValid = false;
      alert('email');
    }
  
    if (!input.phone) {
      isValid = false;
      alert('phone');
    }
  
    if (!input.routingnumber) {
      isValid = false;
      alert('routing');
    }
  
    if (!input.accountnumber) {
      isValid = false;
      alert('acc');
    }
  
    if (!input.addressone) {
      isValid = false;
      alert('addressone');
    }
  
    if (!input.addresstwo) {
      isValid = false;
      alert('addresstwo');
    }
  
    if (!input.city) {
      isValid = false;
      alert('city');
    }
  
    if (!input.state) {
      isValid = false;
      alert('state');
    }
  
    if (!input.zipcode) {
      isValid = false;
      alert('zipcode');
    }
  
    if (!input.ssn) {
      isValid = false;
      alert('ssn');
    }
    
    if (!input.dob) {
      isValid = false;
      alert('dob');
    }
  
    alert(isValid);
    return isValid;
  };
  const handleStripeChange = (event) => {
    setStripe((prevStripe) => ({
      ...prevStripe,
      [event.target.name]: event.target.value,
    }));
  };
  var obj = JSON.parse(window.localStorage.getItem("user"));  

  const updateStripe = async (e) => {
    e.preventDefault();

    if (stripeValidate()) {
      if (stripeStatus !== 'verified') {
        const formData = new FormData();
        Object.entries(stripe).forEach(([key, value]) => {
          formData.append(key, value);
        });

        formData.append('gender', 'male'); // Assuming gender is always 'male'
        formData.append('uid', obj.value);

        try {
          const res = await axios.post(
            'https://domaintobesocial.com/domaintobe/makestripe',
            formData
          );

          if (res.data.status === 'success') {
            document.getElementById('loadingicon').style.display = 'none';
            alert(res.data.message);
            // this.componentDidMount(); // You might need to replace this with the logic you want
          } else {
            alert(res.data.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  };
  const deleteGallery = (event) => {
    event.preventDefault();

    console.log("items length", checkedItems.length);

    if (checkedItems.length === 0) {
      alert("Please select the image");
    } else {
      let curentlogin = JSON.parse(window.localStorage.getItem("user"));
      

      const formData = new FormData();
      formData.append("id", curentlogin.value);
      formData.append("all", checkall);
      formData.append("items", checkedItems);

      axios
        .post(
          "https://domaintobesocial.com/domaintobe/deletegalley",
          formData
        )
        .then((res) => {
          if (res.data.message === "success") {
            // Call useEffect or other logic to update the component
            // componentDidMount();
            setCheckedItems([]);
            setupdate(update+1)
           
          } else {
            console.log("res", res.data.message);
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const handleInputChange = (event) => {
    let newArray = [...checkedItems, event.target.id];

    if (checkedItems.includes(event.target.id)) {
      newArray = newArray.filter((day) => day !== event.target.id);
    }

    setCheckedItems(newArray);
    setCheckAll("");
  };
  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const formData5 = new FormData();
        formData5.append("id", curentlogin.value);

        const response4 = await axios.post(
          "https://domaintobesocial.com/domaintobe/getfollowers",
          formData5
        );

        if (response4.data.status === "data") {
          setFollowers(response4.data.message);
        } else {
          alert(response4.data.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchFollowers();
  }, [curentlogin.value]);
  const unfollow = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      const res = await axios.post(
        "https://domaintobesocial.com/domaintobe/unfollow",
        formData
      );

      console.log(res);

      if (res.data.message === "Success") {
        // Call useEffect or other logic to update the component
        // componentDidMount();
        setFollowers((prevFollowers) =>
          prevFollowers.filter((follower) => follower.id !== id)
        );
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const fetchBlockData = async () => {
      try {
        const response7 = await axios.get(
          "https://domaintobesocial.com/domaintobe/blockget",
          {
            params: {
              userid: curentlogin.value,
            },
          }
        );

        if (response7 && response7.data && response7.data.message) {
          setBlockData(response7.data.message);
        } else {
          console.log("No data or unexpected data format in the response.");
        }
      } catch (err) {
        setBlockData([]);
      }
    };

    fetchBlockData();
  }, [curentlogin.value]);
  useEffect(() => {
    const fetchFollowingData = async () => {

   
      try {
        const formData2 = new FormData();
        formData2.append("id", curentlogin.value);

        const response1 = await axios.post(
          "https://domaintobesocial.com/domaintobe/getfollowing",
          formData2
        );

        if (response1.data.status === "data") {
          setFollowingData(response1.data.message);
        } else {
          alert(response1.data.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchFollowingData();
  }, [curentlogin.value]);
  useEffect(() => {
    const fetchMembershipPlans = async () => {
      try {
        const response5 = await axios.post(
          "https://domaintobesocial.com/domaintobe/membershipplans"
        );

        if (response5 && response5.data && response5.data.result) {
          setPlans(response5.data.result);
        } else {
          console.log("No data or unexpected data format in the response.");
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMembershipPlans();
  }, []);
  

  const handleButtonClick = () => {
    setShowAdvertisement(prevShowAdvertisement => !prevShowAdvertisement);
  };

 

  useEffect(() => {
    const fetchAdvertisement = async () => {
      try {
        const response6 = await axios.get('https://domaintobesocial.com/domaintobe/getadvertisement');
        // console.log(response6);
        setGetAdvertisement(response6.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchAdvertisement();
  }, []);
  
  const handleadvertisementSubmit = async (event) => {
    event.preventDefault();

    if (validated()) {
      setLoading(true);

      try {
        const curentlogin = JSON.parse(window.localStorage.getItem("user"));
        const formData = new FormData();
        formData.append('userid', curentlogin.value);
        formData.append('businessname', input.abusinessname);
        formData.append('advertisement', input.advertisement);
        formData.append('duration', input.duration);
        formData.append('description', input.adescription);

        const res = await axios.post('https://domaintobesocial.com/domaintobe/saveadvertisement', formData);

        setLoading(false);
        swal("Done", res.data.message, "success");
        event.target.reset();
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const validated = () => {
   
    return true;
  };

  const showmenu=()=>{
    window.$('#dropdownMenuButton1').toggleClass("show");
  }
  return (
    <span>
              <div className="loadingicon" id="loadingicon" style={{ display: 'block' }}><img src="/images/loading.gif" /></div>
      {" "}
      <div
        className="inbanner"
        style={{ backgroundImage: `url(${input.bannerimage})` }}
      >
        <span className="uploadImg">
          <img className="camerai" src="/images/camerai.png" alt="your image" />
          Upload
          <input
            className="form-control"
            type="file"
            id="userimage"
            name="userimage"
            onChange={handleUserCover}
            accept=".jpg,.jpeg,.png"
          />
        </span>
      </div>
      <section
        className="dashboard p-0"
        style={{ backgroundImage: `url(/images/${themeimage})` }}
      >
        <div className="container userprofile">
          <div className="dash_top">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="user pro_user" 
                  style={{ backgroundImage: `url(/images/${themeimage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'    }}
                >
                  <div className="uphead">
                  {vipimage}
                    <div className="row-ver">
                    <div className="userimg">
                      <img
                        id="myImg"
                        src="/images/blank.png"
                        alt="your image"
                      />
                      <span className="uploadImg">
                        <img
                          className="camerai"
                          src="/images/camerai.png"
                          alt="your image"
                        />
                        <input
                          className="form-control"
                          type="file"
                          id="userimage"
                          name="userimage"
                          onChange={userimage}
                          accept=".jpg,.jpeg,.png"
                        />
                      </span>
                     
                    </div>
                    <img className="verfiy5" src="../images/markver.png" align="icon"/>
                    </div>
                    <h3>{input.name}</h3>
                    <p>{input.description}</p>
                    <h5>{input.email}</h5>
                    <h6>
                      Your code {input.referralid}
                      <CopyToClipboard
                        text={`https://domaintobesocial.com/Signup?${input.referralid}`}
                        onCopy={() => setCopied(true)}
                      >
                      
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          <i className="fa fa-copy"></i>
                        </span>
                      </CopyToClipboard>
                     
                      {copied ? (
                        <span style={{ color: "red" }}>Copied.</span>
                      ) : null}
                    </h6>
                    <h6>Tags</h6>
                    <ul>
                      {tags?.map((tag, i) =>
                        tag ? <li key={i}>{tag}</li> : ""
                      )}
                    </ul>
                    {/* <h6> Your QR code :-</h6>
                    <QRCode value={`https://domaintobesocial.com/Signup?${input.referralid}`} size={120}/> */}
                    
                  </div>
                </div>
            
              </div>
              <div className="col-md-6">
                      <div className="theme-colordiv">

                     
                {theme}   
                
                  <h5 className="socialicon">
                  {input.facebook && input.facebook !== "" ? (
                    <span>
                      <a href={input.facebook} target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}

                  {input.twitter && input.twitter !== "" ? (
                    <span>
                      <a href={input.twitter} target="_blank">
                      <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}

                  {input.snapchat && input.snapchat !== "" ? (
                    <span>
                      <a href={input.snapchat} target="_blank">
                        <i className="fab fa-snapchat-ghost"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}

                  {input.amazon && input.amazon !== "" ? (
                    <span>
                      <a href={input.amazon} target="_blank">
                        <i className="fab fa-amazon"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}

                  {input.whatsapp && input.whatsapp !== "" ? (
                    <span>
                      <a
                        href={"https://wa.me/" + input.whatsapp}
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}

                  {input.tumbler && input.tumbler !== "" ? (
                    <span>
                      <a href={input.tumbler} target="_blank">
                        <i className="fab fa-tumblr"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}

                  {input.ebay && input.ebay !== "" ? (
                    <span>
                      <a href={input.ebay} target="_blank">
                        <i className="fab fa-ebay"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  {input.tiktok && input.tiktok !== "" ? (
                    <span>
                      <a href={input.tiktok} target="_blank">
                        <i className="fab fa-tiktok"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  {input.Instagram && input.Instagram !== "" ? (
                    <span>
                      <a href={input.Instagram} target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  {input.Reddit && input.Reddit !== "" ? (
                    <span>
                      <a href={input.Reddit} target="_blank">
                        <i className="fab fa-reddit"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  {input.WeChat && input.WeChat !== "" ? (
                    <span>
                      <a href={input.WeChat} target="_blank">
                        <i className="fab fa-weixin"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  {input.telegram && input.telegram !== "" ? (
                    <span>
                      <a href={input.telegram} target="_blank">
                        <i className="fab fa-telegram-plane"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  {input.messenger && input.messenger !== "" ? (
                    <span>
                      <a href={input.messenger} target="_blank">
                        <i className="fab fa-facebook-messenger"></i>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                </h5>
                </div>
                </div>
              <div className="col-md-6">                      <Suggestionbox/>
 </div>
            </div>
          </div>
          <div className="dash_topmain pl-0">
            {/* <i className="fas fa-bars side_b" onClick={openPop}></i> */}
            <div className="dash_sidebar tabsection-rel">
              {/* <i className="fas fa-times side_b close" onClick={openClose}></i> */}
              <ul className="nav nav-tabs tabs-line">
                <li>
                  <Link to="/userdashboard">Home</Link>
                </li>
                <li>
                  <a
                    className="active"
                    data-toggle="tab"
                    onClick={openProfile}
                    href="#bioinfo"
                  >
                    My Profile
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#posts">
                    Posts
                  </a>
                </li>
                {/* <li>
                  <a data-toggle="tab" href="#posts2">
                    Posts2
                  </a>
                </li> */}
                <li>
                  <a data-toggle="tab" href="#social">
                    Social Links
                  </a>
                </li>
      

                <li>
                
                  
                <div className="dropdown drop-more" ref={dropdownRef}>
      <a className="dropdown-toggle" type="button" onClick={toggleMenu}>
        More
      </a>
      {isMenuOpen && (
        <ul className="dropdown-menu show" aria-labelledby="dropdownMenuButton1" id="dropdownMenuButton1">
          {isViprole?businessbutton:''}
          <li>
            <a data-toggle="tab" href="#Friends" onClick={closeMenu}>
              Friends
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#friendrequests" onClick={closeMenu}>
              Friend Requests
            </a>
          </li>
          {galleroption}
          <li>
            <a data-toggle="tab" href="#Followers" onClick={closeMenu}>
              Followers
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#Following" onClick={closeMenu}>
              Following
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#Membership" onClick={closeMenu}>
              Vip Membership
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#Referral" onClick={closeMenu}>
              Referral Details
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#StripeId" onClick={closeMenu}>
              Stripe Id
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#Advertisement" onClick={closeMenu}>
              Advertisement
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#accountdelete" onClick={closeMenu}>
              Account Delete & Change Password
            </a>
          </li>
        </ul>
      )}
    </div>
              
                </li>

               
              </ul>
            </div>
            <div className="tab-content userprofile">
              <div id="bioinfo" className="tab-pane fade show active">
                <div className="bus_det editInformation view1" id="mp1">
                  <h3>
                    My Profile{" "}
                    <a onClick={editpage}>
                      <i className="fa fa-solid fa-edit"></i>
                    </a>
                  </h3>
                  <form>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>First Name</h4>
                          <p>{input.firstname}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Last Name</h4>
                          <p>{input.lastname}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Username</h4>
                          <p>{input.name}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Email address</h4>
                          <p>{input.email}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Phone Number</h4>
                          <p>{input.mobile}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>References</h4>
                          <p>{input.references}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Marital Status</h4>
                          <p>{input.marital_status}</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Address</h4>
                          <p>{input.address}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>
                            Dob<sup>(dd/MM/yyyy)</sup>
                          </h4>
                          <p>{input.age}</p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Tags</h4>
                          <ul className="input-tag__tags">
                            {tags.map((tag, i) =>
                              tag ? (
                                <li key={tag}>
                                  {tag}
                                  <button type="button">+</button>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                            <li className="input-tag__tags__input"></li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Banner Image</h4>
                          {input.bannerimage ? (
                            <img
                              className="w-100"
                              src={input.bannerimage}
                              alt="Banner"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Driving License</h4>
                          {input.drivinglicense ? (
                            <img
                              className="w-100"
                              src={input.drivinglicense}
                              alt="Driving License"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Passport</h4>
                          {input.passportimage ? (
                            <img
                              className="w-100"
                              src={input.passportimage}
                              alt="Passport"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="tes">
                          <h4>Bio</h4>
                          <p>{input.description}</p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="bus_det editInformation diplay1" id="mp2">
                  <h3>Edit Profile</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>
                            First Name{" "}
                            {errors.firstname ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            name="firstname"
                            placeholder="First name"
                            value={input.firstname}
                            onChange={handleChange}
                          />
                          <div className="text-danger">{errors.firstname}</div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Last Name</h4>
                          <input
                            type="text"
                            className="form-control"
                            name="lastname"
                            placeholder="Last name"
                            id="lname"
                            value={input.lastname}
                            onChange={handleChange}
                          />
                          <div className="text-danger">{errors.lastname}</div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Username</h4>
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            name="username"
                            placeholder="Username"
                            value={input.name}
                            onChange={handleChange}
                          />
                          <div className="text-danger">{errors.name}</div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>
                            Email address
                            {errors.email ? (
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Enter email"
                            id="email"
                            value={input.email}
                            onChange={handleChange}
                          />
                          <div className="text-danger">{errors.email}</div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>
                            Phone Number
                            {errors.mobile ? (
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <input
                            type="text"
                            className="form-control"
                            name="mobile"
                            placeholder="Enter number"
                            id="mobile"
                            value={input.mobile}
                            onChange={handleChange}
                          />
                          <div className="text-danger">{errors.mobile}</div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>
                            References
                            {errors.references ? (
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <input
                            type="text"
                            name="references"
                            value={input.references}
                            onChange={handleChange}
                            className="form-control"
                            id="references"
                            autoComplete="off"
                          />
                          <div className="text-danger">{errors.references}</div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Marital Status</h4>
                          <select
                            name="marital_status"
                            defaultValue={input.marital_status}
                            value={input.marital_status}
                            className="form-control"
                            id="marital_status"
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select Marital Status
                            </option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Widowed">Widowed</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Separated">Separated</option>
                            <option value="Certain cases">Certain cases</option>
                            <option value="Registered partnership">
                              Registered partnership
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>
                            Address
                            {errors.address ? (
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <input
                            type="text"
                            className="form-control"
                            name="address"
                            id="address"
                            placeholder="Address"
                            value={input.address}
                            onChange={handleChange}
                          />
                          <div className="text-danger">{errors.address}</div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>
                            Dob<sup>(dd/MM/yyyy)</sup>
                            {errors.age ? (
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <input
                            type="text"
                            className="form-control"
                            name="age"
                            id="age"
                            placeholder="Age"
                            value={input.age}
                            onChange={handleChange}
                          />
                          <div className="text-danger">{errors.age}</div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Tags</h4>
                          <input
                            type="text"
                            className="form-control"
                            onKeyDown={inputKeyDown}
                            ref={tagInput}
                          />

                          <ul className="input-tag__tags">
                            {tags?.map((tag, i) =>
                              tag ? (
                                <li key={tag}>
                                  {tag}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      removeTag(i);
                                    }}
                                  >
                                    +
                                  </button>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                            <li className="input-tag__tags__input"></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Banner Image</h4>
                          <input
                            className="form-control"
                            type="file"
                            id="banner"
                            name="banner"
                            onChange={bannerChange}
                            accept=".jpg,.jpeg,.png"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Driving License</h4>
                          <input
                            className="form-control"
                            type="file"
                            id="drivinglicense"
                            name="drivinglicense"
                            onChange={drivinglicenseChange}
                            accept=".jpg,.jpeg,.png"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Passport</h4>
                          <input
                            className="form-control"
                            type="file"
                            id="passport"
                            name="passport"
                            onChange={passportChange}
                            accept=".jpg,.jpeg,.png"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Student Id</h4>
                          <input
                            className="form-control"
                            type="file"
                            id="studentid"
                            name="studentid"
                            onChange={studentidChange}
                            accept=".jpg,.jpeg,.png"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="tes">
                          <h4>
                            Bio
                            {errors.description ? (
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          <textarea
                            className="form-control"
                            placeholder="Description"
                            id="description"
                            name="description"
                            value={input.description}
                            onChange={handleChange}
                          ></textarea>
                          <div className="text-danger">
                            {errors.description}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      name="submitform"
                      onClick={() => changebutton()}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
              <div id="posts" className="tab-pane fade">
                <Posts />
              </div>
              {/* <div id="posts2" className="tab-pane fade">                
                <div className="linkedin-activity-tab">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Post</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Comments</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Video</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#tabs-4" role="tab">Images</a>
                    </li>
                  </ul>
                
                  <div className="tab-content">
                    <div className="tab-pane active" id="tabs-1" role="tabpanel">
                        <div className="linkdin-post-inner">
                            <div className="posted-time">
                                <p><span>Posted Name</span>Lorem Ipsum is simply dummy text</p>
                            </div>
                            <div className="posted-name-contant">
                                <div className="linkdin-post-img">
                                  <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                                </div>
                                <div className="linkd-content-inner">
                                 <p><span>Name</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>                                  
                                </div>                                
                            </div>
                            <div className="see-more"><a href="#">See More</a></div>
                        </div>
                        <div className="linkdin-post-inner">
                            <div className="posted-time">
                                <p><span>Posted Name</span>Lorem Ipsum is simply dummy text</p>
                            </div>
                            <div className="posted-name-contant">
                                <div className="linkdin-post-img">
                                  <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                                </div>
                                <div className="linkd-content-inner">
                                 <p><span>Name</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>                                  
                                </div>                                
                            </div>
                            <div className="see-more"><a href="#">See More</a></div>
                        </div>
                        <div className="linkdin-post-inner">
                            <div className="posted-time">
                                <p><span>Posted Name</span>Lorem Ipsum is simply dummy text</p>
                            </div>
                            <div className="posted-name-contant">
                                <div className="linkdin-post-img">
                                  <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                                </div>
                                <div className="linkd-content-inner">
                                 <p><span>Name</span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>                                  
                                </div>                                
                            </div>
                            <div className="see-more"><a href="#">See More</a></div>
                        </div>
                    </div>
                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                      <div className="linkdin-post-inner">
                          <div className="posted-time">
                              <p><span>Posted Name</span>Lorem Ipsum is simply dummy text</p>
                          </div>
                          <div className="posted-name-contant">                               
                              <div className="linkd-content-inner">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>                                  
                              </div>                                
                          </div>                           
                        </div>
                        <div className="linkdin-post-inner">
                          <div className="posted-time">
                              <p><span>Posted Name</span>Lorem Ipsum is simply dummy text</p>
                          </div>
                          <div className="posted-name-contant">                               
                              <div className="linkd-content-inner">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>                                  
                              </div>                                
                          </div>                           
                        </div>
                        <div className="linkdin-post-inner">
                          <div className="posted-time">
                              <p><span>Posted Name</span>Lorem Ipsum is simply dummy text</p>
                          </div>
                          <div className="posted-name-contant">                               
                              <div className="linkd-content-inner">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>                                  
                              </div>                                
                          </div>                           
                        </div>
                    </div>
                    <div className="tab-pane" id="tabs-3" role="tabpanel">
                      <div className="linkdin-post-inner">                          
                          <div className="posted-video-info">   
                            <div className="posted-video-inner">
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/D0UnqGm_miA?si=s56vqzphaVBigmPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div> 
                            <div className="posted-video-inner">
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/D0UnqGm_miA?si=s56vqzphaVBigmPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div> 
                            <div className="posted-video-inner">
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/D0UnqGm_miA?si=s56vqzphaVBigmPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div> 
                            <div className="posted-video-inner">
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/D0UnqGm_miA?si=s56vqzphaVBigmPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div> 
                            <div className="posted-video-inner">
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/D0UnqGm_miA?si=s56vqzphaVBigmPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div> 
                            <div className="posted-video-inner">
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/D0UnqGm_miA?si=s56vqzphaVBigmPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>                                                       
                          </div>                           
                        </div>
                    </div>
                    <div className="tab-pane" id="tabs-4" role="tabpanel">
                    <div className="linkdin-post-inner">                          
                          <div className="posted-video-info"> 
                            <div className="posted-video-inner">
                              <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                            </div>
                            <div className="posted-video-inner">
                              <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                            </div>
                            <div className="posted-video-inner">
                              <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                            </div>
                            <div className="posted-video-inner">
                              <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                            </div>
                            <div className="posted-video-inner">
                              <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                            </div>
                            <div className="posted-video-inner">
                              <img src="https://domaintobesocial.com/domaintobe/assets/allimages/Input-12.jpg" />
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div id="social" className="tab-pane fade">
                <div className="bus_det editInformation">
                  <h3>Social</h3>
                  {/* {(() => {
                                console.log('hshhs',errors);  
                                   
                            })()} */}
                  <form onSubmit={handleSubmitSocial}>
                    <div className="row">
                      {/* <div className="col-sm-6">
                                <div className="tes">
                                <h4>First Name</h4>
                                <input type="text" id="name" className="form-control" name="name" placeholder="First name" value={input.name} onChange={handleChange}/>
                                <div className="text-danger">{errors.name}</div>
                                </div>
                                </div>

                                <div className="col-sm-6">
                                <div className="tes">
                                <h4>Last Name</h4>
                                <input type="text" className="form-control" name="lname" placeholder="Last name" id="lname" value={input.lname} onChange={handleChange}/>
                                <div className="text-danger">{errors.lname}</div>
                                </div>
                                </div> */}

                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Instagram</h4>
                          <input
                            type="text"
                            className="form-control"
                            name="Instagram"
                            id="Instagram"
                            placeholder="Instagram  https://Instagram.com/"
                            value={input.Instagram}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>TikTok</h4>
                          <input
                            type="text"
                            className="form-control"
                            name="tiktok"
                            id="tiktok"
                            placeholder="tiktok  https://TikTok.com/"
                            value={input.tiktok}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>Telegram</h4>
                          <input
                            type="text"
                            className="form-control"
                            name="telegram"
                            id="telegram"
                            placeholder="Telegram  https://Telegram.com/"
                            value={input.telegram}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="tes">
                          <h4>messenger</h4>
                          <input
                            type="text"
                            className="form-control"
                            name="messenger"
                            id="ebay"
                            placeholder="Messenger  https://messenger.com/"
                            value={input.messenger}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      {isViprole ? (
                        <>
                          {" "}
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Facebook</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="facebook"
                                id="facebook"
                                placeholder="Facebook https://facebook.com/"
                                value={input.facebook}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Twitter</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="twitter"
                                id="twitter"
                                placeholder="Twitter https://Twitter.com/"
                                value={input.twitter}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Tumbler</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="tumbler"
                                id="tumbler"
                                placeholder="Tumbler https://Tumbler.com/"
                                value={input.tumbler}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Snapchat</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="snapchat"
                                id="snapchat"
                                placeholder="Snapchat https://Snapchat.com/"
                                value={input.snapchat}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Whatsapp</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="whatsapp"
                                id="whatsapp"
                                placeholder="Enter your Whatsapp number"
                                value={input.whatsapp}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Amazon</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="amazon"
                                id="amazon"
                                placeholder="Amazon  https://Amazon.com/"
                                value={input.amazon}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Ebay</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="ebay"
                                id="ebay"
                                placeholder="Ebay  https://Ebay.com/"
                                value={input.ebay}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>Reddit</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="Reddit"
                                id="ebay"
                                placeholder="Reddit  https://Reddit.com/"
                                value={input.Reddit}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="tes">
                              <h4>WeChat</h4>
                              <input
                                type="text"
                                className="form-control"
                                name="WeChat"
                                id="ebay"
                                placeholder="WeChat  https://WeChat.com/"
                                value={input.WeChat}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {fields.map((field, index) => (
                            <div className="col-sm-6" key={index}>
                              <div className="tes">
                                <h4>social </h4>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  name={"social" + index}
                                  placeholder="social   https://social.com/"
                                  onChange={(e) => handleChange2(index, e)}
                                />
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="addmore"
                            onClick={handleAddField}
                          >
                            Add More
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <button type="submit" name="submitform">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
              <div id="business" className="tab-pane fade show">
                <div className="bus_det businessddl">
                  <h3>
                    Business Details{" "}
                    <i
                      className="fa fa-edit"
                      onClick={() => onShowFields()}
                    ></i>
                  </h3>
                  <form onSubmit={handleBusiness}>
                    <div className="tes">
                      <h4>
                        <b>Business Name</b>
                        {childVisible ? (
                          <span>
                            <input
                              type="text"
                              className="form-control"
                              name="buisnessname"
                              id="buisnessname"
                              placeholder="Buisness Name"
                              value={input.buisnessname}
                              onChange={handleChange}
                            />
                            <div className="text-danger">
                              {errors.buisnessname}
                            </div>
                          </span>
                        ) : (
                          <span>{input.buisnessname}</span>
                        )}
                      </h4>
                    </div>
                    <div className="tes">
                      <h4>
                        <b>Profession</b>
                        {childVisible ? (
                          <span>
                            <select
                              className="form-control"
                              name="profession"
                              value={input.profession}
                              onChange={handleProfession}
                              id="profession"
                            >
                              <option key="" value="">
                                --Select Profession--
                              </option>
                              {profession?.map((results) => {
                                return (
                                  <option value={results.id}>
                                    {results.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="text-danger">
                              {errors.profession}
                            </div>
                          </span>
                        ) : (
                          <span>{input.professionview}</span>
                        )}
                      </h4>
                    </div>

                    <div className="tes">
                      <h4>
                        <b>Subcategory Profession</b>
                        {childVisible ? (
                          <span>
                            <select
                              className="form-control"
                              name="subprofession"
                              value={input.subprofession}
                              onChange={handleChange}
                              id="subprofession"
                            >
                              <option key="" value="">
                                --Select Subcategory--
                              </option>
                              {subcategoryprofession?.map((results) => {
                                return (
                                  <option key={results.name} value={results.id}>
                                    {results.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="text-danger">
                              {errors.subprofession}
                            </div>
                          </span>
                        ) : (
                          <span>{input.subprofessionview}</span>
                        )}
                      </h4>
                    </div>

                    <div className="tes">
                      <h4>
                        <b>University</b>
                        {childVisible ? (
                          <span>
                            <input
                              type="text"
                              name="university"
                              value={input.university}
                              onChange={handleChange}
                              className="form-control"
                              id="university"
                              autoComplete="off"
                            />
                            <div className="text-danger">
                              {errors.university}
                            </div>
                          </span>
                        ) : (
                          <span>{input.university}</span>
                        )}
                      </h4>
                    </div>
                    <div className="tes">
                      <h4>
                        <b>Degree Certificate</b>
                        {childVisible ? (
                          <span>
                            <input
                              type="text"
                              name="certificate"
                              value={input.certificate}
                              onChange={handleChange}
                              className="form-control"
                              id="certificate"
                              autoComplete="off"
                            />
                            <div className="text-danger">
                              {errors.certificate}
                            </div>
                          </span>
                        ) : (
                          <span>{input.certificate}</span>
                        )}
                      </h4>
                    </div>
                    {childVisible ? (
                      <div className="tes">
                        <h4>
                          <b>Degree Certificate </b>
                          <span>
                            <input
                              type="file"
                              name="Degree_Certificate_Certificate"
                              onChange={handleLawyersStateCertificate}
                              className="form-control"
                              id="Degree_Certificate_Certificate"
                              autoComplete="off"
                              accept=".jpg,.jpeg,.png"
                            />
                          </span>
                        </h4>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-sm-6 mb-2">
                          <img
                            className="cart w-100"
                            src={input.lawyers_state_certificate}
                          />
                        </div>
                      </div>
                    )}
                    <div className="tes">
                      <h4>
                        <b>State / Province Bar</b>
                        {childVisible ? (
                          <span>
                            <input
                              type="text"
                              name="lawyers_state"
                              value={input.lawyers_state}
                              onChange={handleChange}
                              className="form-control"
                              id="lawyers_state"
                              autoComplete="off"
                            />
                            <div className="text-danger">
                              {errors.lawyers_state}
                            </div>
                          </span>
                        ) : (
                          <span>{input.lawyers_state}</span>
                        )}
                      </h4>
                    </div>
                    {childVisible ? (
                      <div className="tes">
                        <h4>
                          <b>Lawyers State Certificate</b>
                          <span>
                            <input
                              type="file"
                              name="lawyers_state_Certificate"
                              onChange={handleDegree_Certificate_Certificate}
                              className="form-control"
                              id="lawyers_state_Certificate"
                              autoComplete="off"
                              accept=".jpg,.jpeg,.png"
                            />
                          </span>
                        </h4>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-sm-6 mb-2">
                          <img
                            className="cart w-100"
                            src={input.lawyers_state_certificate}
                          />
                        </div>
                      </div>
                    )}
                    {childVisible ? (
                      <div className="tes">
                        <h4>
                          <b>Business Licence</b>
                          <span>
                            <input
                              type="file"
                              name="licence"
                              onChange={licenseImage}
                              className="form-control"
                              id="licence"
                              autoComplete="off"
                              accept=".jpg,.jpeg,.png"
                            />
                          </span>
                        </h4>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="tes">
                      <h4>
                        <b>Working Days</b>
                        {childVisible ? (
                          <span>
                            <select
                              className="form-control"
                              id="days"
                              name="days"
                              value={input.days}
                              onChange={handleChange}
                            >
                              <option value="">--Select Days--</option>
                              <option>1 Day</option>
                              <option>2 Days</option>
                              <option>3 Days</option>
                              <option>4 Days</option>
                              <option>5 Days</option>
                              <option>6 Days</option>
                              <option>7 Days</option>
                            </select>
                            <div className="text-danger">{errors.days}</div>
                          </span>
                        ) : (
                          <span>{input.days}</span>
                        )}
                      </h4>
                    </div>
                    <div className="tes">
                      <h4>
                        <b>From Time</b>
                        {childVisible ? (
                          <span>
                            <input
                              type="time"
                              className="input-time form-control"
                              initialTime={from == false ? "00:00" : from}
                              onChange={handleChange}
                              name="from"
                              id="from"
                            />
                          </span>
                        ) : (
                          <span>{from}</span>
                        )}
                      </h4>
                    </div>
                    <div className="tes">
                      <h4>
                        <b>To Time</b>
                        {childVisible ? (
                          <span>
                            <input
                              type="time"
                              className="input-time form-control"
                              initialTime={to == false ? "00:00" : to}
                              onChange={handleChange}
                              name="to"
                              id="to"
                            />
                          </span>
                        ) : (
                          <span>{to}</span>
                        )}
                      </h4>
                    </div>
                    <div className="tes">
                      <h4>
                        <b>Business Card</b>
                        {childVisible ? (
                          <span>
                            <input
                              type="file"
                              multiple
                              name="card"
                              className="form-control"
                              onChange={handleCardChange}
                              id="card"
                              autoComplete="off"
                              accept=".jpg,.jpeg,.png"
                            />
                          </span>
                        ) : (
                          <div className="row">
                            {businesscardimages.map((businesscardimage, i) => (
                              <div className="col-sm-6 mb-2">
                                <img
                                  className="cart w-100"
                                  src={businesscardimage}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </h4>
                    </div>
                    <div>
                      <button type="submit" name="" value="Submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div id="Friends" className="addfrbfil tab-pane fade">
                <h3>All Friends</h3>
                <div className="row">
                  {friendsdata&&friendsdata?.map((result, i) => {
                    return (
                      <>
                        {blockdata &&
                        blockdata.filter(
                          (item) =>
                            item.friendid.includes(result.friendid) &&
                            item.status === 1
                        ).length > 0 ? (
                          ""
                        ) : (
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                            <div className="testfrnd friend ">
                              <span className="userimg">
                                {/* <span><i className="fas fa-video"></i></span> */}
                                <img src={result.image} align="icon" />
                              </span>
                              <h5>{result.name}</h5>
                              <p>
                                Lorem Ipsum is simply dummy text. simply dummy
                                text.
                              </p>
                              <ul className="followmessage flowmsg ">
                                <li>
                                  <a
                                    onClick={() =>
                                      unFriendrequest( result.id)
                                    }
                                  >
                                    Remove
                                  </a>
                                </li>
                                <li>
                                  {/* <a
                                    className="mg"
                                    onClick={() => {
                                      window.location.href =
                                        "/viewprofile/" +
                                        result.name;
                                    }}
                                  > */}
                                     <Link
                         className="mg"
                          to={{ pathname: "/viewprofile/" + result.name }}
                        >
                                    View Profile
                                    </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div id="friendrequests" className="addfrbfil tab-pane fade">
                        <h3>All Friends Requests</h3>
                        <div className="row">

                            {friendsrequests&&friendsrequests?.map((resultf, i) => {
                            return (
                            <div className="col-lg-6 mb-3">
                                <div className="testfrnd">
                                    <span className="userimg">
                                        {/* <span><i className="fas fa-video"></i></span> */}
                                        <img src={resultf.image ? resultf.image : "images/useri_1.png"} align="icon"/></span>
                                    <h5>{resultf.name}</h5>
                                    <ul className="followmessage flowmsg ">
                                        <li><a onClick={() => acceptFriendrequest(i, resultf.id)}>Accept Request</a></li>
                                        <li><a className="mg" onClick={() => {window.location.href="/viewprofile/"+resultf.userid+'/'+resultf.name}}>View Profile</a></li>
                                    </ul>
                                </div>
                            </div>
                            )
                            })}

                        </div>
                    </div>
              <div id="Gallery" className="tab-pane fade">
                <h3>Gallery</h3>
                <div style={{ display: "flex" }}>
                  {astrickSignImageInput ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "18px",
                        padding: "14px",
                      }}
                    >
                      *
                    </span>
                  ) : (
                    ""
                  )}
                  {gallerybutton}
                </div>
                <div className="row allvideoimages">
                  {galleryimages&&galleryimages?.map((galleryimage, i) => (
                    <div className="col-sm-6 col-lg-4 mb-3" key={i}>
                      {i === 0 ? (
                        <div className="imagetest first-imagetest">
                          <div className="removemultipleimages">
                            <input
                              type="checkbox"
                              value="all"
                              onChange={handleInputAllGallery}
                              id="all"
                            />
                            Select All
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={deleteGallery}
                            >
                              Delete
                            </button>
                          </div>
                          <input
                            type="checkbox"
                            value={i}
                            onChange={handleInputChange}
                            id={i}
                            checked={checkall}
                           
                          />
                          {galleryimage.image ? (
                            <a href={galleryimage.image} data-fancybox>
                              <img
                                className="w-100"
                                src={galleryimage.image}
                                alt="ion"
                              />
                            </a>
                          ) : (
                            <video
                              width="320"
                              height="240"
                              controls
                              src={galleryimage.video}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="imagetest">
                          <input
                            type="checkbox"
                            value={i}
                            onChange={handleInputChange}
                            id={i}
                            
                          />
                          {galleryimage.image ? (
                            <a href={galleryimage.image} data-fancybox>
                              <img
                                className="w-100"
                                src={galleryimage.image}
                                alt="ion"
                              />
                            </a>
                          ) : (
                            <video
                              width="320"
                              height="240"
                              controls
                              src={galleryimage.video}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div id="Followers" className="addfrbfil tab-pane fade">
                <h3>Followers</h3>
                <div className="row">
                  {followers&&followers?.map((resultfo) => (
                    <>
                      {blockdata &&
                      blockdata.filter(
                        (item) =>
                          item.friendid.includes(resultfo.userid) &&
                          item.status === 1
                      ).length > 0 ? (
                        ""
                      ) : (
                        <div className="col-lg-6 mb-3" key={resultfo.id}>
                          <div className="testfrnd Followers">
                            <span className="userimg">
                              {/* <span><i className="fas fa-video"></i></span> */}
                              <img src={resultfo.image} alt="icon" />
                            </span>
                            <h5>{resultfo.name}</h5>
                            <ul className="followmessage flowmsg">
                              <li>
                                <Link
                                  className="mg"
                                  to={`/viewprofile/${resultfo.name}`}
                                  
                                >
                                  View Profile
                                </Link>
                              </li>
                              <li>
                                <a onClick={() => unfollow(resultfo.id)}>
                                  Unfollow
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div id="Following" className="addfrbfil tab-pane fade">
                <h3>Following</h3>
                <div className="row">
                  {followingData&&followingData?.map((results) => (
                    <>
                      {blockdata &&
                      blockdata.filter(
                        (item) =>
                          item.friendid.includes(results.friendid) &&
                          item.status === 1
                      ).length > 0 ? (
                        ""
                      ) : (
                        <div className="col-lg-6 mb-3" key={results.id}>
                          <div className="testfrnd Following">
                            <span className="userimg">
                              {/* <span><i className="fas fa-video"></i></span> */}
                              <img src={results.image} alt="icon" />
                            </span>
                            <h5>{results.name}</h5>
                            <ul className="followmessage flowmsg">
                              <li>
                              <Link
                                  className="mg"
                                  to={`/viewprofile/${results.name}`}
                                  
                                >
                                  View Profile
                                </Link>
                              </li>
                              <li>
                                <a onClick={() => unfollow(results.id)}>
                                  Unfollow
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
          
              <div id="Membership" className="addfrbfil tab-pane fade">
                <h3>Membership</h3>
                <div className="row">
      {plans&&plans?.map((plan, i) => (
        <div className="col-lg-4 mb-3" key={i}>
          <div className="testup">
            <div className="test">
              <div className="head_me">
                <h5>
                  {plan.duration} Plan
                  {input.plan === plan.id ? (
                    <span style={{ float: 'right', color: 'red' }}>{input.planstatus}</span>
                  ) : (
                    <span></span>
                  )}
                </h5>
                <h4>${plan.price}</h4>
              </div>
              <h5>Features</h5>
              <ul>
              {plan.quickposting ? (
                                               <li>Quick Posting using feed along with features like attaching photo, video, tagging user and themselves too and using emojis</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.messageboard ? (
                                               <li>Posting on Message board to start a discussion</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.markingprofile ? (
                                               <li>Marking profile hidden while adding comments</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.customprofile ? (
                                               <li>Creating custom profile page with different colour theme, Banner, photos, video, bio, URL and Location</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.feed ? (
                                               <li>Posting feed or Discussion thread as private with custom duration and password access with share function</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.membershiprenewal  ? (
                                               <li>To get discounts on membership renewal by allowing ads on profile page</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.chatvideo  ? (
                                               <li>To receive requests for chat , video call and Help information</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.galleryfiles  ? (
                                               <li>Multiple delete of gallery files</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.posts  ? (
                                               <li>Search posts by date</li>
                                              ) : (
                                               <li></li>
                                            )}

                                            {plan.livevideo  ? (
                                               <li>Live video streaming</li>
                                              ) : (
                                               <li></li>
                                            )}
              </ul>

              <UpgreadePlan price={plan.price} plan={plan.id} form={input.uid} />

              {input.plan === plan.id ? (
                <button style={{ float: 'right' }} className="btn btn-success">
                  Current Plan
                </button>
              ) : (
                <span></span>
              )}

              {input.plan === plan.id ? (
                <h5>
                  Renew on: <span> {input.expireddate}</span>
                </h5>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
              </div>
              <div id="Referral" className="tab-pane fade">
      <h3>Referral Earning</h3>
      <div className="row">
        <div className="table-responsive">
          <table className="table resdiv">
            <thead>
              <tr>
                <th scope="col">Count</th>
                <th scope="col">User Name</th>
                <th scope="col">Earning Amount</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {referralData&&referralData.map((n, num) => (
                <tr key={num}>
                  <th scope="row">{num + 1}</th>
                  <td>{n.name}</td>
                  <td>{Number(n.price) * (Number(n.comm) / 100)}</td>
                  <td>{getFormattedDate(n.date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
              <div id="StripeId" className="tab-pane fade">
              <h3>Stripe {stripeStatus}</h3>
              <div className="bus_det editInformation">
                        <form onSubmit={updateStripe}>
                            <div className="row">

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Account holder name</h4>
                                    <input type="text" id="holdername" className="form-control" name="holdername" placeholder="Account Holder Name" value={stripe.holdername} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>First Name</h4>
                                    <input type="text" id="firstname" className="form-control" name="firstname" placeholder="First Name" value={stripe.firstname} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Last Name</h4>
                                    <input type="text" id="lastname" className="form-control" name="lastname" placeholder="Last Name" value={stripe.lastname} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Gender</h4>
                                    <select name="gender" className="form-control" id="gender" onChange={handleStripeChange }>
                                        <option value="Male" selected>Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Email</h4>
                                    <input type="text" id="email" className="form-control" name="email" placeholder="Email" value={stripe.email} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Phone Number</h4>
                                    <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone Number" value={stripe.phone} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Routing Number</h4>
                                    <input type="text" id="routingnumber" className="form-control" name="routingnumber" placeholder="Routing Number" value={stripe.routingnumber} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Account Number</h4>
                                    <input type="text" id="accountnumber" className="form-control" name="accountnumber" placeholder="Account Number" value={stripe.accountnumber} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>


                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Address 1</h4>
                                    <input type="text" id="addressone" className="form-control" name="addressone" placeholder="Address One" value={stripe.addressone} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Address 2</h4>
                                    <input type="text" id="addresstwo" className="form-control" name="addresstwo" placeholder="Address Two" value={stripe.addresstwo} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>


                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>City</h4>
                                    <input type="text" id="city" className="form-control" name="city" placeholder="City" value={stripe.city} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>


                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>State</h4>
                                    <input type="text" id="state" className="form-control" name="state" placeholder="State" value={stripe.state} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Zip Code</h4>
                                    <input type="text" id="zipcode" className="form-control" name="zipcode" placeholder="zipcode" value={stripe.zipcode} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Social Security Number last 4 Digit</h4>
                                    <input type="text" id="ssn" className="form-control" min="4" max="4" name="ssn" placeholder="ssn" value={stripe.ssn} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Company Name</h4>
                                    <input type="text" id="ssn" className="form-control" min="4" max="4" name="company_name" placeholder="Company Name" value={stripe.company_name} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>Tin Number</h4>
                                    <input type="text" id="ssn" className="form-control" min="4" max="4" name="company_tin" placeholder="Tin Number" value={stripe.company_tin} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="tes">
                                    <h4>DOB</h4>
                                    <input type="date" id="dob" className="form-control"  name="dob" placeholder="dob" value={stripe.dob} onChange={handleStripeChange }/>
                                    <div className="text-danger"></div>
                                </div>
                            </div>

                        </div>
                        {stripeStatus=='verified' ? <p>Your Stripe Account is verified</p> : <button type="submit" name="submitform">submit</button>}
                            </form>
                            </div>
    </div>
<div id="Advertisement" className="tab-pane fade">
      <div>
        <button onClick={handleButtonClick} className='btn btn-primary mb-4'>View Advertisement</button>
        {showAdvertisement && (
          <div className='row mb-2'>
            {getAdvertisement &&
              getAdvertisement
                .filter(item => item.userid === JSON.parse(window.localStorage.getItem("user")).value)
                .map((item, index) => (
                  <div className='col-sm-4 mt-1' key={index}>
                    <div className="card" style={{ width: "18rem" }}>
                      <div className="card-body">
                        <h5 className="card-title">{item.businessname}</h5>
                        <p className="card-text text-dark">
                          <span className="badge bg-light text-dark">advertisement</span> : {item.advertisement}<br />
                          <span className="badge bg-light text-dark"> description </span> : {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}
      </div>
      <h3>Advertisement</h3>
      <div className="bus_det editInformation">
      <form onSubmit={handleadvertisementSubmit}>

<div className="row">
    <div className="col-sm-6">
        <div className="tes">
            <h4>Business name {errors.abusinessname ? <span style={{color: "red", marginLeft: "5px"}}>*</span> : ''}</h4>
            <input type="text" id="abusinessname" className="form-control" name="abusinessname" placeholder="Business name" onChange={handleChange} autoComplete="off"/>
            <div className="text-danger">{errors.abusinessname}</div>
        </div>
    </div>

    <div className="col-sm-6">
        <div className="tes">
            <h4>Type of advertisement {errors.advertisement ? <span style={{color: "red", marginLeft: "5px"}}>*</span> : ''}</h4>
            <input type="text" id="advertisement" className="form-control" name="advertisement" placeholder="Type of advertisement"  onChange={handleChange} autoComplete="off"/>
            <div className="text-danger">{errors.advertisement}</div>
        </div>
    </div>



    <div className="col-sm-6">
        <div className="tes">
            <h4>Duration {errors.duration ? <span style={{color: "red", marginLeft: "5px"}}>*</span> : ''}</h4>
            <select className="form-control" name="duration" onChange={handleChange} id="duration" autoComplete="off">
                <option value="" style={{color: "black"}}>--Select Duration--</option>
                <option style={{color: "black"}}>One Week</option>
                <option style={{color: "black"}}>One Month</option>
            </select>
            <div className="text-danger">{errors.duration}</div>
        </div>
    </div>

    <div className="col-sm-12">
        <div className="tes">
        <h4>Description {errors.adescription ? <span style={{color: "red", marginLeft: "5px"}}>*</span> : ''}</h4>
        <textarea className="form-control" placeholder="Description" id="description" name="adescription"  onChange={handleChange}></textarea>
        <div className="text-danger">{errors.adescription}</div>
        </div>

        <button >Submit</button>
    </div>
</div>

</form>
      </div>
    </div>
    <div id="accountdelete" className="tab-pane fade">
 

 
    <ChangePasswordForm className='mt-3'/>
    <button type="button" className="btn btn-danger m-2" onClick={handleDelete}>Account Delete</button>
    </div>

    {/* <div id="Setting" className="tab-pane fade">
    <select className="form-select-sm" aria-label="select example">
  <option>1</option>
  <option>2</option>
  <option>3</option>
  <option>4</option>
  <option>5</option>
</select>


    </div> */}
            </div>
          </div>
        </div>
      </section>
    </span>
  );
};

export default Userprofile;
