import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import swal from "sweetalert";
import Picker from "emoji-picker-react";
import UserContext from '../../Hooks/UserContext';
const EditFeed = ({closeModal,checkedItems,urlify,isID,data,setVisiblePopupIndex,setCheckedItems}) => {
    const [Editdata, setEditdata] = useState([]);
    const [posttype, setposttype] = useState('public');
    const [enteredTextedit, setEnteredTextedit] = useState([]);
    const [imagesPreviewUrlsedit, setImagesPreviewUrlsedit] = useState([]);
    const [videosPreviewUrlsedit, setVideosPreviewUrlsedit] = useState([]);
    const [files, setFiles] = useState([]);
    const [formfilled, setFormfilled] = useState("notempty");
    const {updatevalue, setupdatevalue} = useContext(UserContext);
    const [input, setInput] = useState({});
    const [videos, setVideos] = useState([]);
    const [userimage, setUserimage] = useState("/images/blank.png");
    const [isViprole, setIsViprole] = useState(false);
    let curentlogin = JSON.parse(window.localStorage.getItem("user"));
    const [childVisible, setChildVisible] = useState(false);
    const onEmojiClick = (event, emojiObject) => {
        console.log(emojiObject);
        setEnteredTextedit((prevEnteredText) => [
          ...prevEnteredText,
          emojiObject.emoji,
        ]);
      };
      useEffect(()=>{
      
            let filtervalue = data.filter(item=>item.id===isID)
           
                 setEditdata(filtervalue[0])
                
                  
                   const formData1 = new FormData();
                   formData1.append('uid', curentlogin.value);
                   formData1.append('pid', isID);
               
                   try {
                     axios.post('https://domaintobesocial.com/domaintobe/getFeedById', formData1)
                       .then(res => {
                         if (res.data.details.length === 0) {
                        
                         } else {
                          
                           setEnteredTextedit(res.data.details.posts);
                           setposttype(res.data.details.posttype);
                           setImagesPreviewUrlsedit(res.data.details.images?.split(','));
                           setVideosPreviewUrlsedit(res.data.details.videos?.split(','));
                         }
                       });
                   } catch (e) {
                     console.log('e-message', e.message);
                   }
                  
               
      },[isID])
    const handleClick = () => {
        setChildVisible((prevChildVisible) => !prevChildVisible);
      };
    const _handleVideoChangeedit = (e) => {
        e.preventDefault();
        let newFiles = Array.from(e.target.files);
    
        newFiles.forEach((file) => {
          let reader = new FileReader();
          reader.onloadend = () => {
            const filesize = Math.round(file.size / 1024);
            if (filesize > 200048) {
              alert("File too large, please select a file less than 2mb");
            } else {
              setVideos((prevVideos) => [...prevVideos, file]);
              setVideosPreviewUrlsedit((prevUrls) => [...prevUrls, reader.result]);
            }
          };
          reader.readAsDataURL(file);
        });
      };
    useEffect(()=>{
        const fetchData = async () => {
            try {
            const formData = new FormData();
            formData.append("id", curentlogin.value);
            formData.append("user", curentlogin.value);
    
            const response = await axios.post(
              "https://domaintobesocial.com/domaintobe/getuserprofile",
              formData
            );
    
            setInput({
              name: response.data.message.name,
              email: response.data.message.email,
              uid: response.data.message.id,
            });
    
            setFormfilled(response.data.message.formfilled);
    
            if (response.data.message.roles === "vip") {
              setIsViprole(true);
            } else {
              setIsViprole(false);
            }
    
            setUserimage(response.data.message.image || "/images/blank.png");
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }}
          fetchData();
      },[])
    const _handleImageChangeedit = (e) => {
        e.preventDefault();
        let newFiles = Array.from(e.target.files);
    
        newFiles.forEach((file) => {
          let reader = new FileReader();
          reader.onloadend = () => {
            const filesize = Math.round(file.size / 1024);
            if (filesize > 200048) {
              swal(
                "!Oops",
                "File too large, please select a file less than 2mb",
                "error"
              );
            } else {
              setFiles((prevFiles) => [...prevFiles, file]);
              setImagesPreviewUrlsedit((prevUrls) => [...prevUrls, reader.result]);
            }
          };
          reader.readAsDataURL(file);
        });
      };
    const removeImageedit = (i) => {
        const newArray = [...imagesPreviewUrlsedit];
        newArray.splice(i, 1);
        setImagesPreviewUrlsedit(newArray);
      };
    
      const removeVideoedit = (i) => {
        const newArray = [...videosPreviewUrlsedit];
        newArray.splice(i, 1);
        setVideosPreviewUrlsedit(newArray);
      };
      const handlepostupload = async (event) => {
        event.preventDefault();
      
        if (formfilled === "empty") {
          alert("Complete your personal details");
          window.location = "/userprofile";
          return false;
        } else {
          document.getElementById("loadingicon").style.display = "block";
      
          const postcontent = enteredTextedit;
          const html = urlify(postcontent);
      
          const obj = JSON.parse(window.localStorage.getItem("user"));
          const formData = new FormData();
          formData.append("userid", obj.value);
          formData.append("postid", isID);
          formData.append("post", html);
      
          // Only append posttype if available in input
          formData.append("posttype", input.posttype || posttype);
      
          // Maintain existing images if no new images are added
          if (imagesPreviewUrlsedit && imagesPreviewUrlsedit.length > 0) {
            formData.append("selectImage", JSON.stringify(imagesPreviewUrlsedit));
          }
      
          // Append new videos if available
          if (videosPreviewUrlsedit && videosPreviewUrlsedit.length > 0) {
            formData.append("selectVideo", JSON.stringify(videosPreviewUrlsedit));
          }
      
          // Append files and videos if selected
          files.forEach((file) => formData.append("files[]", file));
          videos.forEach((file) => formData.append("videos[]", file));
          formData.append("tagged", JSON.stringify(checkedItems));
      
          try {
            const res = await axios.post("https://domaintobesocial.com/domaintobe/updatenewsfeed", formData);
            document.getElementById("loadingicon").style.display = "none";
            setEnteredTextedit("");
            setImagesPreviewUrlsedit([]);
            setVideosPreviewUrlsedit([]);
            setCheckedItems([]);
      
            if (res.data.message === "success") {
              setVisiblePopupIndex(null);
              setupdatevalue(updatevalue + 1);
              window.$("#exampleModal13").modal("hide");
              closeModal();
              swal("Successfully", "Post Update done", "success");
            } else {
              alert(res.data.message);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      };
      
  return (
    <><div
    className="modal fade"
    id="exampleModal13"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content border-0 main-wapper-popup">
        <div className="modal-header border-0">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit Post
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
      <span aria-hidden="true">×</span>
    </button>
        </div>
        <div className="modal-body post-body">
          <div className="create-post-popup">
            <div className="d-flex ">
              <a href="" className="profile-info">
              {Editdata&&Editdata.username?.substring(0,1)}
              </a>
              <div>
                <h4>{Editdata&&Editdata.username}</h4>
                <div className="dropdown custom-drop">
                <select name="posttype" className="form-control" value={posttype} onChange={e=>setposttype(e.target.value)}>
                    
                    <option value="public">Public</option>
             
                    <option value="nonvip">Non Vip</option>
                    {isViprole&&<option value="vip">VIP</option>}
                  </select>
                </div>
              </div>
            </div>
            <div className="text-area-part mt-3 d-flex">
              <textarea
                name=""
                id=""
                className="form-control"
                rows={1}
                placeholder="What's on your mind?"
                value={enteredTextedit || ""} 
                onChange={(e) =>
                  setEnteredTextedit(
                   e.target.value, 
                  )
                }
              />
              <a href="#">
                <img
                  src="https://clipart-library.com/images/6Tr5bBo8c.png"
                  width={19}
                  alt=""
                />
              </a>
            </div>
            <div className="w-100 img-section">
           
              <img
                src={imagesPreviewUrlsedit&&imagesPreviewUrlsedit[0]
                }
                width="100%"
                alt=""
              />
            </div>
            <div className="maindivkap">
        <div className="row">
          {imagesPreviewUrlsedit&&imagesPreviewUrlsedit?.map((imagePreviewUrl, i) => (
            <div className="col-4 col-sm-3 col-lg-2" key={i} onClick={() => removeImageedit(i)}>
              <div className="imgg">
                <i className="fa fa-times"></i>
                <img className="upim w-100" alt="previewImg" src={imagePreviewUrl} />
              </div>
            </div>
          ))}
          {videosPreviewUrlsedit&&videosPreviewUrlsedit?.map((videoPreviewUrl, i) => (
            <div className="col-sm-3 col-lg-2" key={i} onClick={() => removeVideoedit(i)}>
              <div className="imgg">
                <i className="fa fa-times"></i>
                <video width="320" height="240" controls>
                  <source src={videoPreviewUrl} type="video/mp4" />
                </video>
              </div>
            </div>
          ))}
        </div>
        {childVisible && <Picker onEmojiClick={onEmojiClick} />}
      </div>
          </div>
        </div>
        <div className="footer-post d-flex justify-content-between align-items-center modal-footer border">
        <ul className="popupli">
        {isViprole && (<li><a href="#" className="dropdown" data-toggle="modal" data-target="#exampleModalHelp"><i className="fa-solid fa-user-plus" /></a></li>)}
      <li>
        <a href="#">
          <input type="file" name="" onChange={_handleImageChangeedit} multiple accept="image/*" className="addimagepost" />
          <i className="fa-solid fa-image" />
        </a>
      </li>
      {isViprole && (
        <>
          <li>
            <a href="#">
              <input type="file" name="" onChange={_handleVideoChangeedit} multiple accept="video/*" className="addimagepost" />
              <i className="fa-solid fa-photo-film" />
            </a>
          </li>
          <li>
            <a href="#" >
              <i className="fa-solid fa-square-poll-vertical" />
            </a>
          </li>
        </>
      )}
      <li><a href="#"  onClick={handleClick}><i className="fa-regular fa-face-smile"/></a></li>
    </ul>
          <button onClick={(e)=>handlepostupload(e)}>Save</button>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default EditFeed