import React, { useEffect, useState } from 'react'
import { ref ,onValue,getDatabase, update, push, set  } from 'firebase/database';
import $ from 'jquery'
import appvalue from '../../firebase';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ReadMoreReact from 'read-more-react/dist/components/ReadMoreReact';
const MessageList = () => {
  const [filterValue, setFilterValue] = useState('');
  const [showdata, setShowdata] = useState([]);
  const [popupchat, setPopupchat] = useState([]);

  const [inputFields, setInputFields] = useState([{ name: '' }]);
  const [imagesPreviewUrls2, setImagesPreviewUrls2] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [input, setInput] = useState({
    name: '',
    email: '',
    uid: '',
  });

  const [formfilled, setFormfilled] = useState('');
  const [isViprole, setIsViprole] = useState(false);
  const [userImage, setUserImage] = useState('/images/blank.png');

const [chatingData, setChatingData] = useState([]);
  const [messageNotificationCount, setMessageNotificationCount] = useState({});
  const [minimized, setMinimized] = useState([]);
  const today = new Date().toLocaleDateString();
  const curentlogin = JSON.parse(window.localStorage.getItem("user"));
  const db = getDatabase(appvalue);
  const forchanedosage = (index, event) => {
    // Add a null check for event.target
    if (event && event.target) {
        const updatedInputFields = [...inputFields];
        updatedInputFields[index].name = event.target.value;
        setInputFields(updatedInputFields);
    }
};

  const _handleImageChange2 = (e) => {
    e.preventDefault();
    let files = Array.from(e.target.files);

    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        const filesize = Math.round(file.size / 1024);
        if (filesize > 200048) {
          swal("!Oops", "File too large, please select a file less than 2mb", "error");
        } else {
          setFiles2((prevFiles) => [...prevFiles, file]);
          setImagesPreviewUrls2((prevUrls) => [...prevUrls, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  useEffect(() => {
    const fetchChatData = async () => {
     
      const today = new Date().toLocaleDateString();

      const chatRef = ref(db, "chatwith/" + curentlogin.value);
      const unsubscribeChat = onValue(chatRef, (snapshot) => {
        let chatingdatas = [];
        let notifications = {};

        snapshot.forEach((snap) => {
          chatingdatas.push(snap.val());

          let id = snap.val().uid;

          const chatId = id + '_' + curentlogin.value;
          const chatNotificationRef = ref(db, "chat/" + chatId);

          const unsubscribeNotifications = onValue(chatNotificationRef, (snapshot) => {
            let count = 0;

            snapshot.forEach((snap1) => {
              const notification = snap1.val();
              const notificationDate = new Date(notification.time).toLocaleDateString();

              if (notification.read === "y" && notification.side === "right" && notificationDate === today) {
                count++;
              }
            });

            notifications[id] = count;
            setMessageNotificationCount(notifications);
          });

          // Cleanup function for chat notifications listener
          return () => unsubscribeNotifications();
        });

        setChatingData(chatingdatas);
      });

      // Cleanup function for chat data listener
      return () => unsubscribeChat();
    };

    fetchChatData();
  }, [curentlogin.value]);

  useEffect(() => {
    const fetchChatsAndNotifications = async () => {
      const db = getDatabase();

      const chatwithRef = ref(db, "chatwith/" + curentlogin.value);
      onValue(chatwithRef, snapshot => {
        let chatingdatas = [];
        let notifications = {};

        snapshot.forEach(snap => {
          chatingdatas.push(snap.val());

          let id = snap.val().uid;

          const chatRef = ref(db, "chat/" + id + '_' + curentlogin.value);
          onValue(chatRef, snapshot => {
            let count = 0;

            snapshot.forEach(snap1 => {
              const notification = snap1.val();
              const notificationDate = new Date(notification.time).toLocaleDateString();

              if (notification.read === "y" && notification.side === "right" && notificationDate === today) {
                count++;
              }
            });

            notifications[id] = count;
          });

          // Update state outside the loop
          setMessageNotificationCount(notifications);
        });

    
        setChatingData(chatingdatas);
      });
    };

    fetchChatsAndNotifications();
  }, [curentlogin.value, today]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const formData = new FormData();
      formData.append('id', curentlogin.value);
      formData.append('user', curentlogin.value);

      try {
        const response = await axios.post('https://domaintobesocial.com/domaintobe/getuserprofile', formData);

        setInput({
          name: response.data.message.name,
          email: response.data.message.email,
          uid: response.data.message.id,
        });

        if (response.data.message.roles === 'vip') {
          setFormfilled(response.data.message.formfilled);
          setIsViprole(true);
        } else {
          setIsViprole(false);
        }

        if (!response.data.message.image || response.data.message.image === '') {
          setUserImage('/images/blank.png');
        } else {
          setUserImage(response.data.message.image);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchUserProfile();
  }, [curentlogin.value]);
  const closeChatbox = (id) => {
    // Remove the chatbox with the specified id
    const filteredArray = showdata.filter((item) => item.id !== id);
    setShowdata(filteredArray);

    // Remove the 'main' class from chat-popup
    document.querySelector('.chat-popup').classList.remove('main');
  };

  const startChat = async (id, image, name, j, e) => {
    e.preventDefault();

    const sender = `${curentlogin.value}_${id}`;
    const reciever = `${id}_${curentlogin.value}`;
    const time = new Date().toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
    const senderRef = ref(db, 'chat/' + sender);
    const receiverRef = ref(db, 'chat/' + reciever);
    const chatwithRef1 = ref(db, `chatwith/${curentlogin.value}/${id}`);
const chatwithRef2 = ref(db, `chatwith/${id}/${curentlogin.value}`);
const lastchatRef = ref(db, `lastchat/${curentlogin.value}`);

    if (imagesPreviewUrls2.length !== 0) {
      const formData = new FormData();
      files2.forEach((file) => formData.append('files[]', file));

      try {
        const response = await axios.post('https://domaintobesocial.com/domaintobe/chatimage', formData);
        push(senderRef, {
          read: 'y',
          side: 'right',
          msg: `${inputFields[j].name} ${response.data.message || ''}`,
          image: userImage,
          time,
        });

        push(receiverRef, {
          read: 'n',
          side: 'left',
          msg: `${inputFields[j].name} ${response.data.message || ''}`,
          image: userImage,
          time,
        });

        setImagesPreviewUrls2([]);
      } catch (error) {
        console.log(error.message);
      }
    }

    if (inputFields[j].name.length !== 0) {
      push(senderRef, {
        read: 'y',
        side: 'right',
        msg: inputFields[j].name,
        image: userImage,
        time,
      });

      push(receiverRef, {
        read: 'n',
        side: 'left',
        msg: inputFields[j].name,
        image: userImage,
        time,
      });
    }

    set(chatwithRef1, {
      uid: id,
      name: name , 
      image,
      msg: inputFields[j].name,
      time,
    });
    set(chatwithRef2, {
      uid: curentlogin.value,
      name: name,
      image: image,
      msg: inputFields[j].name,
      time,
    });
    

    set(lastchatRef, {
      uid: id,
      name: name,  // Provide a default value if 'name' is undefined
      image,
      msg: inputFields[j].name,
      time,
    });

    setInputFields((prevInputFields) => {
      const updatedFields = [...prevInputFields];
      updatedFields[j].name = '';
      return updatedFields;
    });

    $(".chatstart").stop().animate({ scrollTop: $(".chatstart")[0].scrollHeight }, 1000);
  };


  const openChatbox = (id, name, image) => {
    $('.chat-popup').addClass('main');
  
    const inds = showdata.findIndex((value) => value.id === id);
  
    if (inds === -1) {
      // Update the 'read' field in the database
      const chatRef1 = ref(db, `chat/${id}_${curentlogin.value}`);
      onValue(chatRef1, (snapshot) => {
        snapshot.forEach((snap) => {
          update(ref(db, `chat/${id}_${curentlogin.value}/${snap.key}`), { read: 'n' });
        });
      });
  
      const chatRef2 = ref(db, `chat/${curentlogin.value}_${id}`);
      onValue(chatRef2, (snapshot) => {
        const chatingdatas = [];
        snapshot.forEach((snap) => {
          chatingdatas.push(snap.val());
        });

        setPopupchat(chatingdatas);
  
        const key = 'id';
        const arr = showdata;
        arr.push({ id, name, image });
  
        const newarray = [...new Map(arr.map((item) => [item[key], item])).values()];
  
        setInputFields([...inputFields, { name: '' }]);
  
        setShowdata(newarray, () => {
          $('.chatstart').stop().animate({ scrollTop: $('.chatstart')[0].scrollHeight }, 1000);
        });
      });
    } else {
      const filteredArray = showdata.filter((item) => item.props.id !== id);
      setShowdata(filteredArray, () => {
        $('.chatstart').stop().animate({ scrollTop: $('.chatstart')[0].scrollHeight }, 1000);
      });
    }
  };
  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filteredData = filterValue
  ? (chatingData && Array.isArray(chatingData)
    ? chatingData.filter((item) => {
        return item.name && typeof item.name === 'string' && item.name.toLowerCase().includes(filterValue.toLowerCase());
      })
    : [])
  : chatingData;
  const minimize = (id) => {
    setMinimized((prevMinimized) => {
      if (prevMinimized.includes(id)) {
        return prevMinimized.filter((item) => item !== id);
      } else {
        return [...prevMinimized, id];
      }
    });
  };

  let popupchat1=(id)=>
  {
      let chatingdatas = [];
      const chatRef = ref(getDatabase(), "chat/" + curentlogin.value + '_' + id);
   
     onValue(chatRef, snapshot => {
      snapshot.forEach(snap => {
        chatingdatas.push(snap.val());
      });
     
    })
    return chatingdatas;
  }

  

  return (
    <>
    <div className="side_right">
      <div className="test showchatt" key={curentlogin.value}>
        <h3 >Messages list</h3>
        <div className="all mmss">
          <form className="d-flex w-100 px-2">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={filterValue || ''}
              onChange={handleFilterChange}
            />
            <button className="btn" type="submit">
              <img src="images/searchicon.png" alt="icon" />
            </button>
          </form>
          {filteredData.length > 0 &&
            filteredData.map((chat, i) => (
              <div className="testin"  onClick={() => openChatbox(chat.uid, chat.name, chat.image)}>
            
                <div className="images">
                <NavLink to={{ pathname: `/viewprofile/${chat.name}` }}>   <img src={chat.image} alt="user" /></NavLink>
                </div>
                <img className="verfiymsg" src="../images/markver.png" align="icon"/>
                <h4>
                  {chat.name} {<sup style={{ color: '#ff0000d6' }}>{messageNotificationCount[chat.uid]}</sup>}
                </h4>
                <p>
                  {chat.msg && (
                    <ReadMoreReact 
                    text={chat.msg?chat.msg:""}
                    numberOfLines={1}
                    showLessButton={true}
                    readMoreText="click here to read more"
                   />
                  )}
                </p>
                <h6>{chat.time}</h6>
              </div>
            ))}
        </div>
      </div>
    </div>

    <div id="display-data-Container" className="chat-popup">
      {showdata.map((x, j) => (
        <div className="appendchatuser" id={x.id} key={j}>
          <h1>
            <Link to={{ pathname: `/viewprofile/${x.name}` }}>{x.name}</Link>
            <span id={`chat${x.id}`} onClick={() => minimize(x.id)}>
              <i className="fa fa-window-minimize" aria-hidden="true"></i>
              <i className="fa fa-window-maximize" aria-hidden="true"></i>
            </span>
            <button type="button" className="btn cancel" onClick={() => closeChatbox(x.id)}>
              <i className="fas fa-times"></i>
            </button>
          </h1>
        
          <form onSubmit={(e) => startChat(x.id, x.image, x.name, j,e)} className="form-container">
            <div className="chatstart">
              {popupchat1(x.id) && popupchat1(x.id).length > 0 ? (
                popupchat1(x.id).map((chat, i) => (
                  <span key={i}>
                    {chat.side === 'left' ? (
                      <div className="container_left">
                        <img src={chat.image} alt="Avatar" />
                        {chat.msg.endsWith('.mp4') ? (
                          <video className="chatvideo" controls>
                            <source src={chat.msg} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : chat.msg.endsWith('.png') || chat.msg.endsWith('.jpg') || chat.msg.endsWith('.jpeg') ? (
                          <img src={chat.msg} alt="Image" className="chatimage" style={{ height: '100px', position: 'relative' }} />
                        ) : (
                          <p>{chat.msg}</p>
                        )}
                        <span className="time-right">{chat.time}</span>
                      </div>
                    ) : (
                      <div className="container_left darker">
                        {console.log(chat.image)}
                        <img src={chat.image} alt="Avatar" className="right" />
                        {chat.msg.endsWith('.mp4') ? (
                          <video className="chatvideo" controls>
                            <source src={chat.msg} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : chat.msg.endsWith('.png') || chat.msg.endsWith('.jpg') || chat.msg.endsWith('.jpeg') ? (
                          <img src={chat.msg} alt="Image" className="chatimage" style={{ height: '100px', position: 'relative' }} />
                        ) : (
                          <p>{chat.msg}</p>
                        )}
                        <span className="time-left">{chat.time}</span>
                      </div>
                    )}
                  </span>
                ))
              ) : (
                <p>No record found</p>
              )}
            </div>

            <textarea
              placeholder="Type message.."
              name="message"
              autoComplete="off"
              value={inputFields[j].name} onChange={(event) => forchanedosage(j, event)}
            />

<input
  id="file-upload"
  type="file"
  style={{ display: 'none' }}
  onChange={(e) => _handleImageChange2(e, j)}
  multiple
  accept="image/*, .jpg, .jpeg, .png"
/>
            <img src="images/addicon1.png" align="icon" className="chatuploadfile" onClick={() => document.getElementById('file-upload').click()} />
            <button type="submit" name="chatsubmit" className="btn">
              Send
            </button>
          </form>
        </div>
      ))}
    </div>
    </>
  )
}

export default MessageList