import React, { useRef, useState } from 'react';
import UserStories from './UserStories';
import axios from 'axios';
import $ from 'jquery'
import swal from 'sweetalert';

const StorySection = () => {
    const modalRef = useRef(null);

    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [uploadtype, setuploadtype] = useState(null);
    const [update, setupdate] = useState(0);
    const [loading,setloading] = useState(false)

    const handleTitleChange = (e) => {
      setTitle(e.target.value);
    };
  
    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
      setuploadtype(e.target.files[0].type=='image/jpeg'?'image':e.target.files[0].type=='video/mp4'?'video':'')
    };
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      const userId = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();
      setloading(true)
    //   formData.append('title', title);
      formData.append('user_id',  userId.value);
      formData.append('file', file);
      formData.append('file_type', uploadtype);
    if(file!==null){  
      try {
        const response = await axios.post('https://domaintobesocial.com/domaintobe/api/stories', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        if (response.status === 201) {
 
          swal("Successfully", "Story created successfully.", "success");
          setFile(null)
          setloading(false)
          if (modalRef.current) {
         
            window.$(modalRef.current).modal('hide');
            setupdate(update+1);
           
          }
        } else {
       
          console.error('Error uploading story:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading story:', error);
      }}else{
        swal("Error", "Please select files", "error");
        setloading(false)
      }
     
      setFile(null);
    };

  return (
    <div className="story-section">
      <div className="create-story"  data-toggle="modal" data-target="#exampleModalCenter2">
        <div className="create-story-content">
          <div className="create-story-icon">
            {/* <span>+</span> */}
            <img src='images/stroy.png'/>
          </div>
          {/* <p>Create Story</p> */}
        </div>
      </div>
      <div className="modal fade"  ref={modalRef} id="exampleModalCenter2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalCenterTitle">Create Story</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <form onSubmit={handleSubmit} className="add-story-form">
      {/* <div className="form-group">
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter story title"
        />
      </div> */}
      <div className="form-group">
        <label htmlFor="file">Upload Story</label><br/>
        <input
          type="file"
          id="file"
          onChange={handleFileChange}
          accept="image/*,video/*"
        />
      </div>
    
      <button type="submit" className="btn btn-primary">Add Story</button>  {loading?"loading...":""}
    </form>
      </div>
  
    </div>
  </div>
</div>
      <UserStories update1={{update}}/>
    </div>
  );
};

export default StorySection;
