import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Picker from "emoji-picker-react";
import ReadMoreReact from "read-more-react";
// import firebase from 'firebase';
import $ from "jquery";
import swal from "sweetalert";
import Searchbar from "../Searchbar/Searchbar";
import MessageList from "../MessageList/MessageList";
// import 'firebase/messaging';
import firebase from "firebase/app";
import "firebase/database";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import StorySection from "../Createstory/AddStoryForm";
import Menubar from "../menu-section/Menubar";
import Notificationpop from "../Viewnotifications/Notificationpop";

function Header() {
  let handleChangeLogout=()=>
    {
      window.localStorage.clear();
      window.location.reload()
    }
  const [update, setUpdate] = useState(0);
  const [zoomedIndex, setZoomedIndex] = useState(null);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [isViprole, setIsViprole] = useState(false);
  const [userimage, setUserimage] = useState("/images/blank.png");
  const [comments, setComments] = useState("");
  const [hidecomment, setHidecomment] = useState("");
  const [values, setValues] = useState("");
  const [verified, setverified] = useState(false);
  const [formfilled, setFormfilled] = useState("notempty");
  const [files, setFiles] = useState([]);

  const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);

  const [videosPreviewUrls, setVideosPreviewUrls] = useState([]);
  const [videos, setVideos] = useState([]);
  const [enteredText, setEnteredText] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);
  const [childVisible, setChildVisible] = useState(false);

  const [searchoption, setSearchoption] = useState("");

  const [commentinput, setCommentinput] = useState(false);
  const [commentdataid, setCommentdataid] = useState("");

  const [blockdata, setBlockdata] = useState("");
  const [searchedData, setSearchedData] = useState([]);

  const myRef = useRef();
  const navigate = useNavigate();
  let curentlogin = JSON.parse(window.localStorage.getItem("user"));
  let handleHideComment = (i, e) => {
    if (e.target.checked) {
      setHidecomment({
        hidecomment: { ...hidecomment, [i]: e.target.value },
      });
    } else {
      setHidecomment({
        hidecomment: { ...hidecomment, [i]: "" },
      });
    }
  };
  const removeImage = (i) => {
    const newArray = [...imagesPreviewUrls];
    newArray.splice(i, 1);
    setImagesPreviewUrls(newArray);
  };

  const removeVideo = (i) => {
    const newArray = [...videosPreviewUrls];
    newArray.splice(i, 1);
    setVideosPreviewUrls(newArray);
  };

  let openReplycomment = (i, id, post) => {
    document.getElementById("rid" + id).style.display = "block";
  };
  const postClick = (i) => {
    document.getElementById("loadingicon").style.display = "block";
    const formData = new FormData();
    formData.append("id", i);

    axios
      .post(
        "https://domaintobesocial.com/domaintobe/getuserspostsbyid",
        formData
      )
      .then((res) => {
        setData(res.data.message);
        setSearchedData([]);
        document.getElementById("loadingicon").style.display = "none";
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const commentLike = (i, id, post) => {
    if (formfilled === "empty") {
      alert("Complete your personal details");
      window.location = "/userprofile";
      return false;
    } else {
      let userid = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();
      formData.append("userid", userid.value);
      formData.append("commentid", id);
      formData.append("feedid", post);

      axios
        .post(
          "https://domaintobesocial.com/domaintobe/postcommentlike",
          formData
        )
        .then((res) => {
          if (res.data.message === "success") {
            // fetchComments(); // Refresh comments after successful like
            setUpdate(update + 1);
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  const handleGetreply = (i, e) => {
    setComments((prevComments) => ({ ...prevComments, [i]: e.target.value }));
  };

  const commentSubmit = (i, e) => {
    e.preventDefault();

    if (!comments) {
      swal("Oops!", "Please fill the comment field", "error");
      return;
    }

    document.getElementById("loadingicon").style.display = "block";

    let userid = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append("userid", userid.value);
    formData.append("comment", comments[i]);
    formData.append("hidecomment", hidecomment[i]);
    formData.append("feedname", data[i].username);
    formData.append("feedemail", data[i].useremail);
    formData.append("postuser", data[i].postuser);
    formData.append("posts", data[i].posts);
    formData.append("sendername", input.name);
    formData.append("senderemail", input.email);
    formData.append("feedid", e.target.attributes["data-tag"].value);

    axios
      .post("https://domaintobesocial.com/domaintobe/commentsonfeeds", formData)
      .then((res) => {
        if (res.data.message === "success") {
          setComments("");
          e.target.reset();
          document.getElementById("loadingicon").style.display = "none";
          setUpdate(update + 1);
          // Assuming you have a function to update the component state after comment submission
          // Update this line accordingly based on your component structure
          // Example: updateCommentsState();
        } else {
          swal("Oops!", res.data.message, "error");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  //   useEffect(() => {
  //     blockdatashow();
  //   }, []);
  const postLike = (i, id) => {
    if (formfilled === "empty") {
      alert("Complete your personal details");
      window.location = "/userprofile";
      return false;
    } else {
      document.getElementById("loadingicon").style.display = "block";
      const userId = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();

      formData.append("userid", userId.value);
      formData.append("postid", id);
      formData.append("feedname", data[i].username);
      formData.append("feedemail", data[i].useremail);
      formData.append("postuser", data[i].postuser);
      formData.append("posts", data[i].posts);
      formData.append("sendername", input.name);
      formData.append("senderemail", input.email);

      axios
        .post("https://domaintobesocial.com/domaintobe/postlike", formData)
        .then((res) => {
          if (res.data.message === "success") {
            setUpdate(update + 1);
            document.getElementById("loadingicon").style.display = "none";
            // window.location.reload();
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const _handleImageChange = (e) => {
    e.preventDefault();
    let newFiles = Array.from(e.target.files);

    newFiles.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        const filesize = Math.round(file.size / 1024);
        if (filesize > 200048) {
          swal(
            "!Oops",
            "File too large, please select a file less than 2mb",
            "error"
          );
        } else {
          setFiles((prevFiles) => [...prevFiles, file]);
          setImagesPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const validates = () => {
    let currentInput = input;
    let currentErrors = {};
    let isValid = true;
    if (!currentInput["category"]) {
      isValid = false;
      currentErrors["category"] = "Category field is required.";
    }
    if (!currentInput["description"]) {
      isValid = false;
      currentErrors["description"] = "Description is required.";
    }
    setErrors(currentErrors);
    return isValid;
  };

  const handleReportSubmit = (event) => {
    event.preventDefault();
    if (validates()) {
      if (formfilled === "empty") {
        alert("Complete your personal details");
        window.location = "/userprofile";
        return false;
      } else {
        const userId = JSON.parse(window.localStorage.getItem("user"));
        const routeState = JSON.parse(
          window.localStorage.getItem("routeState")
        );
        const formData = new FormData();
        formData.append("userid", userId.value);
        formData.append("feedid", event.target.attributes["data-id"].value);
        formData.append(
          "commentid",
          event.target.attributes["commentid"].value
        );
        formData.append("category", input.category);
        formData.append("description", input.description);
        axios
          .post(
            "https://domaintobesocial.com/domaintobe/postcommentreports",
            formData
          )
          .then((res) => {
            if (res.data.message === "success") {
              setUpdate(update + 1);
              swal("Successfully", "Report Submit", "success");
              window.location.reload();
            } else {
              alert(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    }
  };
  const _handleVideoChange = (e) => {
    e.preventDefault();
    let newFiles = Array.from(e.target.files);

    newFiles.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        const filesize = Math.round(file.size / 1024);
        if (filesize > 200048) {
          alert("File too large, please select a file less than 2mb");
        } else {
          setVideos((prevVideos) => [...prevVideos, file]);
          setVideosPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handleClick = () => {
    setChildVisible((prevChildVisible) => !prevChildVisible);
  };
  const parseURLParams = (url) => {
    var queryStart = url.indexOf("?") + 1,
      queryEnd = url.indexOf("#") + 1 || url.length + 1,
      query = url.slice(queryStart, queryEnd - 1),
      pairs = query.replace(/\+/g, " ").split("&"),
      parms = {},
      i,
      n,
      v,
      nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
      nv = pairs[i].split("=", 2);
      n = decodeURIComponent(nv[0]);
      v = decodeURIComponent(nv[1]);

      if (!parms.hasOwnProperty(n)) parms[n] = [];
      parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response7 = await axios.get(
          "https://domaintobesocial.com/domaintobe/blockget",
          {
            params: {
              userid: curentlogin.value,
            },
          }
        );

        if (response7 && response7.data && response7.data.message) {
          setBlockdata(response7.data.message);
        } else {
          console.log("No data or unexpected data format in the response.");
        }
      } catch (err) {
        setBlockdata([]);
      }
      try {
        const urlString = window.location.href;
        const urlParams = parseURLParams(urlString);

        if (urlParams?.comment) {
          // setLoading(true);

          const formData = new FormData();
          formData.append("search", urlParams.comment[0]);

          const searchRes = await axios.post(
            "https://domaintobesocial.com/domaintobe/searchnewsfeed",
            formData
          );
          console.log("searchNewFeed", searchRes.data.message);

          setSearchedData(searchRes.data.message);

          const userPostsFormData = new FormData();
          userPostsFormData.append("id", searchRes.data.message[0]?.id);

          const userPostsRes = await axios.post(
            "https://domaintobesocial.com/domaintobe/getuserspostsbyid",
            userPostsFormData
          );
          console.log("res", userPostsRes);

          setData(userPostsRes.data.message);
          setSearchedData([]);
          // setLoading(false);
        } else {
          const formData1 = new FormData();
          formData1.append("id", curentlogin.value);

          const newFeedsRes = await axios.post(
            "https://domaintobesocial.com/domaintobe/getnewfeeds",
            formData1
          );
          if (newFeedsRes.data.message !== "false") {
           
            setData(newFeedsRes.data.message);
            setverified(
              newFeedsRes.data.message.emailverified === "1" ? true : false
            );
          }

          // setLoading(false);
        }
      } catch (error) {
        console.error(error.message);
        // setLoading(false);
      }

      try {
        const formData = new FormData();
        formData.append("id", curentlogin.value);
        formData.append("user", curentlogin.value);

        const response = await axios.post(
          "https://domaintobesocial.com/domaintobe/getuserprofile",
          formData
        );

        setInput({
          name: response.data.message.name,
          email: response.data.message.email,
          uid: response.data.message.id,
        });

        setFormfilled(response.data.message.formfilled);

        if (response.data.message.roles === "vip") {
          setIsViprole(true);
        } else {
          setIsViprole(false);
        }

        setUserimage(response.data.message.image || "/images/blank.png");
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [update]);

  const handleZoom = (index) => {
    setZoomedIndex(index === zoomedIndex ? null : index);
  };
  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("search", input.search);

      if (searchoption === "user") {
        const response = await axios.post(
          "https://domaintobesocial.com/domaintobe/getusers",
          formData
        );
        setSearchedData(response.data.message);
      } else {
        formData.append("vip", searchoption === "vip" ? "3" : "");
        const response = await axios.post(
          "https://domaintobesocial.com/domaintobe/searchnewsfeed",
          formData
        );
        setSearchedData(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validate = () => {
      let isValid = true;
      let newErrors = {};

      if (enteredText.length === 0) {
        isValid = false;
        newErrors["post"] = "Please enter post data.";
      }

      setErrors(newErrors);

      return isValid;
    };
    const urlify = (postcontent) => {
      const urlRegex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return postcontent.toString().replace(urlRegex, (url) => {
        return `<a href="${url}" >${url}</a>`;
      });
    };
    if (validate()) {
      if (formfilled === "empty") {
        window.location = "/userprofile";
        return false;
      } else {
        document.getElementById("loadingicon").style.display = "block";

        const html = urlify(enteredText);

        const obj = JSON.parse(window.localStorage.getItem("user"));
        const formData = new FormData();
        formData.append("userid", obj.value);
        formData.append("post", html);
        formData.append("tagged", checkedItems);
        formData.append("url", input.url);
        files.forEach((file) => formData.append("files[]", file));
        videos.forEach((file) => formData.append("videos[]", file));
        formData.append("tagged", JSON.stringify(checkedItems));

        try {
          const res = await axios.post(
            "https://domaintobesocial.com/domaintobe/savenewsfeed",
            formData
          );

          document.getElementById("loadingicon").style.display = "none";
          setEnteredText("");
          setImagesPreviewUrls([]);
          setVideosPreviewUrls([]);
          setFiles([]);
          setVideos([]);

          if (res.data.message === "success") {
            setUpdate(update + 1);
          } else {
            alert(res.data.message);
          }
        } catch (error) {
          console.error("Error submitting data:", error.message);
        }
      }
    }
  };
  const updateState = (e) => {
    let myArr = e.target.value.split(",");
    myArr = myArr.filter((item) => item);

    setEnteredText(myArr);
  };

  const onEmojiClick = (event, emojiObject) => {
    console.log(emojiObject);
    setEnteredText((prevEnteredText) => [
      ...prevEnteredText,
      emojiObject.emoji,
    ]);
  };

  const handleReply = (e, i) => {
    setValues((prevValues) => ({ ...prevValues, [i]: e.target.value }));
  };
  const handleReplySubmit = (e, i) => {
    e.preventDefault();

    if (formfilled === "empty") {
      alert("Complete your personal details");
      window.location = "/userprofile";
      return false;
    } else {
      const userId = JSON.parse(window.localStorage.getItem("user"));
      const formData = new FormData();

      formData.append("userid", userId.value);
      formData.append("commentid", e.target.attributes["commentid"].value);
      formData.append("postid", e.target.attributes["data-id"].value);
      formData.append("comment", values[i]);

      axios
        .post(
          "https://domaintobesocial.com/domaintobe/postreplycomment",
          formData
        )
        .then((res) => {
          if (res.data.message === "success") {
            setUpdate(update + 1);
          } else {
            alert(res.data.message);
          }

          document.getElementById(
            "rid" + e.target.attributes["commentid"].value
          ).style.display = "none";
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  let openPop = () => {
    // alert('asdfasdf');
    $(".maindiv").toggleClass("main");
  };
  return (
    <>
        <div className='Mainheader-wapper mobile-wapper'>
                <div className='logo-site'>
        <Link to={'/userdashboard'}>
            <img src="../images/logo.png" alt="logo" />
            </Link>
                </div>
                <i className="fas fa-bars side_b" onClick={openPop}></i>
                <div className='sarech-filed in_center hidepgo'>
                <div className="head">
        <form className="d-flex" onSubmit={handleSearch}>
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search Post Name and User by Name"
            name="search"
            aria-label="Search"
            autoComplete="off"
            onChange={handleChange}
            value={input.search}
          />
         <select
            onChange={(e) => {
              setSearchoption(e.target.value);
            }}
          >
            <option value="">Select one...</option>
            <option value="user">user</option>
            <option value="vip">Vip</option>
            <option value="post">post</option>
          </select>
          <button className="btn" type="submit">
            <img src="../images/searchicon.png" alt="icon" />{" "}
          </button>
          <div className="setsearchdata">
            <ul>
              {searchedData[0] != "data" ? (
                searchedData.map((results) => {
                  return (
                    <>
                      {results.posts ? (
                        <li
                          className="postsearch"
                       onClick={()=> setSearchedData([])}
                          key={results.id}
                        >
                          <Link
                          className="postsearch"
                          to={{ pathname: "/Notification/" + results.id }}
                        >
                          {results.posts}
                          <i id={results.id} className="fas fa-arrow-right"></i>
                          </Link>
                        </li>
                      ) : results.name ? (
                        <Link
                          className="postsearch"
                          to={{ pathname: "/viewprofile/" + results.name }}
                        >
                          {results.name}
                        </Link>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <li>data not found</li>
                </>
              )}
            </ul>
          </div>
        </form>
        <Link to="/createpost" className="hpl">
          <img src="../images/iconS2.png" align="icon" />{" "}
          <span>Start Discussion</span>
        </Link>
      </div>
    
                </div>
       

         
                
                <div className='admin-right addinfo-new'>
                <div className="phone-icon" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                  <div className="side_right d-flex">
                 
                        <div className="asuser">
                          
                        <Link to="/userprofile">
                            <span className="userimg new-user-yo">
                            <img className="w-100" src={userimage} align="icon" />
                            </span>
                            {verified&&<img className="verfiy" src='../images/markver.png' align="icon" />}

                            {/* {vipimage} */}
                            <h5>{input.name}</h5>
                        </Link>
                        </div>
                        <Notificationpop/>
                        <span
                        className="logout"
                        onClick={handleChangeLogout}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >
                      <i className="fa-solid fa-right-from-bracket"></i>
                        </span>
                    
                    </div>
               
                </div>
        </div>


        <div className="collapse" id="collapseExample">
  <div className="search-box in_center">
  <div className="head">
        <form className="d-flex" onSubmit={handleSearch}>
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search Post Name and User by Name"
            name="search"
            aria-label="Search"
            autoComplete="off"
            onChange={handleChange}
            value={input.search}
          />
         <select
            onChange={(e) => {
              setSearchoption(e.target.value);
            }}
          >
            <option value="">Select one...</option>
            <option value="user">user</option>
            <option value="vip">Vip</option>
            <option value="post">post</option>
          </select>
          <button className="btn" type="submit">
            <img src="../images/searchicon.png" alt="icon" />{" "}
          </button>
          <div className="setsearchdata">
            <ul>
              {searchedData[0] != "data" ? (
                searchedData.map((results) => {
                  return (
                    <>
                      {results.posts ? (
                        <li
                          className="postsearch"
                          onClick={()=> setSearchedData([])}
                          key={results.id}
                        >
                           <Link
                          className="postsearch"
                          to={{ pathname: "/Notification/" + results.id }}
                        >
                          {results.posts}
                          <i id={results.id} className="fas fa-arrow-right"></i>
                          </Link>
                        </li>
                      ) : results.name ? (
                        <Link
                          className="postsearch"
                          to={{ pathname: "/viewprofile/" + results.name }}
                        >
                          {results.name}
                        </Link>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <li>data not found</li>
                </>
              )}
            </ul>
          </div>
        </form>
        <Link to="/createpost" className="hpl Discussion-start">
          <img src="../images/iconS2.png" align="icon" />{" "}

        </Link>
      </div>
  </div>
</div>
    </>
  )
}

export default Header