import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Stories from 'react-insta-stories';

const UserStories = (update1) => {
  const [selectedUserStories, setSelectedUserStories] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0); 
  const [update, setUpdate] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: 432,
    height: 768,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // For mobile devices, adjust dimensions
        setDimensions({ width: window.innerWidth * 0.8, height: window.innerHeight * 0.8 });
      } else {
        // For larger screens
        setDimensions({ width: 420, height: 600 });
      }
    };

    // Set initial dimensions
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    axios
      .get('https://domaintobesocial.com/domaintobe/api/stories')
      .then((res) => setUsers(res.data.message))
      .catch((error) => console.log(error));
  }, [update,update1.update1.update]);

  const handleClick = (userStories) => {
    setSelectedUserStories(userStories);
    setIsModalOpen(true);
    setCurrentStoryIndex(0); // Reset to the first story
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const deleteStory = () => {

    if (selectedUserStories ) {
      const storyId = currentStoryIndex.id; // Get the ID of the current story

      // Close modal and reset selected story
      setIsModalOpen(false);
      setSelectedUserStories(null);

      // Perform delete request
      axios
        .post(`https://domaintobesocial.com/domaintobe/api/stories/delete/${storyId}`)
        .then((res) => {
          setUpdate(update + 1); // Trigger re-fetching the data after deletion
        })
        .catch((error) => console.log(error));
    } else {
      console.log("No story selected or story ID not found");
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {users.map((user, index) => (
          <div
            key={index}
            onClick={() => handleClick(user.stories)}
            style={{
              cursor: 'pointer',
              marginRight: '15px',
              textAlign: 'center',
            }}
          >
            <img
              src={user.stories[0].url}
              alt={user.name}
              style={{ width: '100px', height: '170px', borderRadius: '6px', objectFit: 'cover'  }}
            />
            <p>{user.name}</p>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            zIndex: 1000,
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          }}
        >
          <Stories
            stories={selectedUserStories}
            defaultInterval={3000}
            width={dimensions.width}
            height={dimensions.height}
            onStoryStart={(story, index) => setCurrentStoryIndex(index)} // Track the current story index
            onAllStoriesEnd={closeModal} // Close modal when stories end
            seeMore={()=>{}}
          />
          <button
            onClick={closeModal} // Close the modal on click
            style={{
              zIndex: '9999',
              position: 'absolute',
              top: '40px',
              right: '30px',
              background: 'transparent',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
            }}
          >
           <svg
  xmlns="http://www.w3.org/2000/svg"
  height={14}
  width="15.75"
  viewBox="0 0 384 512"
>
  <path
    fill="#ffffff"
    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
  />
</svg>

          </button>
        <svg
  xmlns="http://www.w3.org/2000/svg"
  height={20}
  width="15.75"
  viewBox="0 0 448 512"
  onClick={deleteStory}
  style={{
    zIndex: '9999',
    position: 'absolute',
    top: '80px',
    right: '35px',
    background: 'transparent',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  }}
>
  {/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}
  <path
    fill="#74C0FC"
    d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"
  />
</svg>

        </div>
      )}

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={closeModal}
        ></div>
      )}
    </>
  );
};

export default UserStories;
